import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { MeetingState } from "@/types/meeting";

interface FinishMeetingDialogProps {
  meetingId: string;
  userId: string;
}

const FinishMeetingDialog = ({
  meetingId,
  userId,
}: FinishMeetingDialogProps) => {
  const queryClient = useQueryClient();
  const { isPending, mutate: finishMeeting } = useMutation({
    mutationFn: (data: { state: string }) =>
      fetch(`/api/meetings/${meetingId}`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
          updated_by_id: userId,
          "Content-Type": "application/json",
        },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["meetings"] });
    },
  });

  const handleFinishMeeting = () => {
    finishMeeting({ state: MeetingState.done });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="rounded bg-gray-800 px-6 font-semibold text-white transition duration-300 hover:bg-gray-700 focus:ring-0">
          Done
        </Button>
      </DialogTrigger>
      <DialogContent className="border-0 bg-primary">
        <DialogHeader>
          <DialogTitle className="text-white">Finish meeting</DialogTitle>
        </DialogHeader>
        <h1 className="text-white">
          Are you sure you want to set this meeting as done?
        </h1>
        <div className="flex justify-end gap-x-2">
          <Button
            className="text-white hover:bg-slate-300 hover:text-accent-foreground focus:ring-0"
            variant="outline"
            disabled={isPending}
            onClick={() => handleFinishMeeting()}
          >
            Yes
          </Button>
          <DialogClose>
            <Button
              className="text-white hover:bg-slate-300 hover:text-accent-foreground focus:ring-0"
              variant="outline"
            >
              No
            </Button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FinishMeetingDialog;
