import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import { CheckCircle, ChevronUp } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Checkbox } from "@/components/ui/checkbox";
import { Collapsible, CollapsibleContent } from "@/components/ui/collapsible";
import { ActionItem } from "@/types/action_item";
import { formatMeetingType } from "@/utils/formatMeetingType";
import { getInitials } from "@/utils/getInitials";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

interface ToDoActionItemCollapsibleProps {
  actionItems: ActionItem[];
  userId: number;
  ownProfile: boolean;
  refetch: () => void;
}

const ToDoActionItemCollapsible = ({
  actionItems,
  userId,
  ownProfile,
  refetch,
}: ToDoActionItemCollapsibleProps) => {
  const [isRowExpanded, setIsRowExpanded] = useState<boolean[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setIsRowExpanded(new Array(actionItems.length).fill(false));
  }, [actionItems]);

  const toggleExpand = (index: number) => {
    setIsRowExpanded((prev) =>
      prev.map((expanded, i) => (i === index ? !expanded : expanded)),
    );
  };

  const { mutateAsync: updateActionItem, isPending } = useMutation({
    mutationFn: (data: ActionItem) =>
      fetch(`/api/meetings/${data.meeting.id}/action-item/${data.id}`, {
        method: "PATCH",
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          completed: !data.completed,
        }),
      }),
    onSuccess: () => refetch(),
  });

  const message = ownProfile
    ? "You have no action items at the moment."
    : "They have no action items at the moment.";

  return (
    <div className="text-white">
      {!actionItems.length ? (
        <div className="mt-10 flex h-full flex-col items-center justify-center text-slate-400">
          <CheckCircle className="mb-4 h-12 w-12 text-yellow-300" />
          <p className="mb-1 text-lg font-semibold text-white">
            All Caught Up!
          </p>
          <p className="text-sm text-slate-400">{message}</p>
        </div>
      ) : (
        <div className="mt-4 overflow-hidden rounded-lg">
          {actionItems.map((item, row) => (
            <Collapsible open={isRowExpanded[row]} key={item.id}>
              <div className="flex items-center justify-between bg-black/30 px-3 py-3">
                <div
                  className="flex items-center gap-x-2 hover:cursor-pointer"
                  onClick={() => {
                    toggleExpand(row);
                  }}
                >
                  <div
                    className={`duration-400 transition-transform ${
                      isRowExpanded[row] ? "" : "rotate-180"
                    }`}
                  >
                    <ChevronUp className="h-5 w-5" />
                  </div>
                  <p className="text-lg">{item.title}</p>
                </div>

                <div className="flex items-center gap-x-4">
                  <p>{format(new Date(item.due_date), "EEEE, d MMMM, yyyy")}</p>
                  <Checkbox
                    className="size-6"
                    checked={item.completed}
                    onClick={() => {
                      updateActionItem(item);
                    }}
                    disabled={isPending}
                  />
                </div>
              </div>
              <CollapsibleContent>
                <div className="space-y-2 bg-gray-800 px-4 py-2">
                  <div>
                    <p className="font-bold">Description:</p>
                    <p>{item.description}</p>
                  </div>

                  <div className="flex items-center gap-x-4">
                    <div className="flex items-center gap-x-2">
                      <p className="font-bold">Created By:</p>
                      <div
                        className="flex items-center gap-x-2 hover:cursor-pointer"
                        onClick={() =>
                          navigate(`/employees/${item.creator.id}`)
                        }
                      >
                        <Avatar
                          className="h-8 w-8"
                          data-testid={`avatar-image-${item.creator.id}`}
                        >
                          <AvatarImage src={item.creator.avatarUrl} />
                          <AvatarFallback className="bg-gray-600 font-bold text-white">
                            {getInitials(item.creator.name)}
                          </AvatarFallback>
                        </Avatar>
                        <p>{item.creator.name}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex items-center gap-x-2 hover:cursor-pointer"
                    onClick={() => navigate(`/meetings/${item.meeting.id}`)}
                  >
                    <p className="font-bold">
                      {item.meeting.meeting_type
                        ? `${formatMeetingType(item.meeting.meeting_type)} Meeting`
                        : "Type"}{" "}
                      -{" "}
                      {item.meeting.meeting_date
                        ? format(
                            new Date(item.meeting.meeting_date),
                            "EEEE, d MMMM, yyyy",
                          )
                        : "Date"}
                    </p>
                  </div>
                </div>
              </CollapsibleContent>
            </Collapsible>
          ))}
        </div>
      )}
    </div>
  );
};
export default ToDoActionItemCollapsible;
