import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { DateTimeInput } from "@/components/inputs/DateTimeInput";
import SelectInput from "@/components/inputs/SelectInput";
import TextAreaInput from "@/components/inputs/TextAreaInput";
import { TextInput } from "@/components/inputs/TextInput";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import type { Participant } from "@/types/user";
import { NewActionItemFormData, newActionItemFormSchema } from "./schema";

interface NewActionItemFormProps {
  targets: Participant[];
  meetingId: number;
  userId: number;
  onClose: () => void;
}

export const NewActionItemForm = ({
  targets,
  meetingId,
  userId,
  onClose,
}: NewActionItemFormProps) => {
  const queryClient = useQueryClient();

  const form = useForm<NewActionItemFormData>({
    resolver: zodResolver(newActionItemFormSchema),
    defaultValues: {},
  });

  const { mutate: createActionItem } = useMutation({
    mutationFn: (data: {
      title: string;
      description: string;
      due_date: string;
      assigned: number;
      completed: boolean;
    }) =>
      fetch(`/api/meetings/${meetingId}/action-item`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          token: userId.toString(),
        },
      }),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["meetings"] });
      onClose();
    },
  });

  const onSubmit = (data: NewActionItemFormData) => {
    const payload = {
      title: data.title,
      description: data.description,
      due_date: data.due_date.toISOString(),
      assigned: parseInt(data.assigned, 10),
      completed: false,
    };
    createActionItem(payload);
  };

  return (
    <Form {...form}>
      <form className="space-y-2" onSubmit={form.handleSubmit(onSubmit)}>
        <TextInput
          control={form.control}
          name="title"
          label="Title"
          placeholder="Title"
        />
        <TextAreaInput
          control={form.control}
          name="description"
          label="Description"
          placeholder="Description"
        />
        <SelectInput
          control={form.control}
          name="assigned"
          label="Assignee"
          items={targets}
          className="max-w-[280px]"
          placeholder="Select an assignee"
          valueAccessor={(target) => target.user_id.toString()}
          labelAccessor={(target) => target.name}
        />
        <DateTimeInput
          control={form.control}
          name="due_date"
          label="Due date"
          removetime={true}
        />
        <div className="flex items-center justify-end gap-x-2">
          <Button className="text-white" variant="outline" type="submit">
            Save
          </Button>
          <Button className="text-white" variant="outline" onClick={onClose}>
            Discard
          </Button>
        </div>
      </form>
    </Form>
  );
};
