import { Route, Routes, useLocation } from "react-router-dom";
import type { Location } from "react-router-dom";

import { Layout } from "@/layout";
import { Home, NotFound } from "@/screens";
import { ActionItems } from "@/screens/action-items/index";
import { EmployeeList } from "@/screens/employees";
import { EmployeeDetails } from "@/screens/employees/id";
import { Login } from "@/screens/Login";
import { MeetingDetails } from "@/screens/meetings/id";
import { Meetings } from "@/screens/meetings/index";
import { CreateMeetingScreen } from "@/screens/meetings/new";
import { NoteDetails } from "@/screens/notes/id";
import { Notes } from "@/screens/notes/index";
import CreateNoteScreen from "@/screens/notes/new";
import { RequestDetails } from "@/screens/requests/id";
import { FeedbackDetails } from "@/screens/requests/id/feedbacks/id";
import { SendFeedback } from "@/screens/requests/id/new-feedback";
import { RoundsScreen } from "@/screens/rounds";
import { ReceivedFeedbacks } from "@/screens/rounds/id/received-feedbacks";
import { Requests } from "@/screens/rounds/id/requests";
import { CreateRequestScreen } from "@/screens/rounds/id/requests/new";
import { SentFeedbacks } from "@/screens/rounds/id/sent-feedbacks";
import { ModalRouter } from "./ModalRouter";
import { ProtectedRoute } from "./ProtectedRoute";
import { ROUTES } from "./routes";

export const Router = () => {
  const location = useLocation();
  const { previousLocation } = (location.state ?? {}) as {
    previousLocation?: Location;
  };

  return (
    <>
      <Routes location={previousLocation ?? location}>
        {/* PUBLIC ROUTES */}
        <Route element={<ProtectedRoute expected="signedOut" />}>
          <Route element={<Layout />}>
            <Route element={<Login />} path={ROUTES.login} />
          </Route>
        </Route>

        {/* MANAGER ROUTES */}
        <Route element={<ProtectedRoute expected={["manager"]} />}>
          <Route element={<Layout />}>
            <Route element={<EmployeeList />} path={ROUTES.employeeList} />
            <Route element={<Requests />} path={ROUTES.requests} />
            <Route
              element={<CreateRequestScreen />}
              path={ROUTES.createRequest}
            />
            <Route
              element={<CreateMeetingScreen />}
              path={ROUTES.createMeeting}
            />
          </Route>
        </Route>

        {/* USER ROUTES */}
        <Route element={<ProtectedRoute expected={["manager", "user"]} />}>
          <Route element={<Layout />}>
            <Route element={<Home />} path={ROUTES.home} />
            <Route element={<NoteDetails />} path={ROUTES.noteId} />
            <Route element={<CreateNoteScreen />} path={ROUTES.createNote} />
            <Route element={<EmployeeDetails />} path={ROUTES.employeeId} />
            <Route path={ROUTES.notFound} element={<NotFound />} />
            <Route element={<Meetings />} path={ROUTES.meetings} />
            <Route element={<Notes />} path={ROUTES.notes} />
            <Route element={<MeetingDetails />} path={ROUTES.meetingId} />
            <Route element={<ActionItems />} path={ROUTES.actionItem} />
            <Route element={<RequestDetails />} path={ROUTES.requestId} />
            <Route element={<FeedbackDetails />} path={ROUTES.feedbackId} />
            <Route
              element={<ReceivedFeedbacks />}
              path={ROUTES.receivedFeedbacks}
            />
            <Route element={<SendFeedback />} path={ROUTES.submitFeedback} />
            <Route element={<SentFeedbacks />} path={ROUTES.sentFeedbacks} />
            <Route element={<RoundsScreen />} path={ROUTES.rounds} />
          </Route>
        </Route>
      </Routes>

      {/* MODALS ROUTES */}
      <Routes>
        <Route
          path="*"
          element={<ModalRouter showModal={!!previousLocation} />}
        />
      </Routes>
    </>
  );
};
