import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import DashboardCalendar from "@/components/calendar/DashboardCalendar";
import { MeetingsCarousel } from "@/components/carousels/MeetingsCarousel";
import ToDoActionItemCollapsible from "@/components/collapsibles/ToDoActionItemsCollapsible";
import LoadingScreen from "@/components/LoadingScreen";
import { useUserStore } from "@/stores";
import { ActionItem } from "@/types/action_item";
import type { Meeting } from "@/types/meeting";
import { ErrorBoundaryFallback } from "./ErrorBoundaryFallback";

export const Home = () => {
  const { user } = useUserStore();
  const userId = user!.id;
  const now = useMemo(() => new Date(), []);

  const {
    data: actionItems,
    isLoading: isLoadingActionItems,
    error: errorActionItems,
    refetch,
  } = useQuery({
    queryKey: ["actionItems"],
    queryFn: (): Promise<ActionItem[]> =>
      fetch("api/action-items/", {
        method: "GET",
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => response.data),
  });

  const incompleteActionItems = useMemo(() => {
    return (
      actionItems
        ?.filter((actionItem) => !actionItem.completed)
        .sort(
          (a, b) =>
            new Date(a.due_date).getTime() - new Date(b.due_date).getTime(),
        ) ?? []
    );
  }, [actionItems]);

  const {
    data: response,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["meetings", userId],
    queryFn: (): Promise<{ data: Meeting[] }> =>
      fetch("/api/meetings", {
        method: "GET",
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      }).then((response) => response.json()),
    enabled: !!userId,
  });

  const meetings: Meeting[] = useMemo(() => response?.data ?? [], [response]);

  const formattedMeetings = useMemo(() => {
    return meetings
      .filter((meeting) => new Date(meeting.meeting_date) >= now)
      .sort(
        (a: Meeting, b: Meeting) =>
          new Date(a.meeting_date).getTime() -
          new Date(b.meeting_date).getTime(),
      );
  }, [meetings, now]);

  const actionItemDates = useMemo(
    () => incompleteActionItems.map((item) => new Date(item.due_date)),
    [incompleteActionItems],
  );

  const meetingDates = useMemo(
    () => formattedMeetings.map((meeting) => new Date(meeting.meeting_date)),
    [formattedMeetings],
  );

  const matchingDates = useMemo(() => {
    const actionItemDatesSet = new Set(
      actionItemDates.map((date) => date.toDateString()),
    );
    return meetingDates.filter((date) =>
      actionItemDatesSet.has(date.toDateString()),
    );
  }, [actionItemDates, meetingDates]);

  if (isLoadingActionItems || isLoading) {
    return <LoadingScreen />;
  }

  if (errorActionItems || !actionItems || error) {
    return <ErrorBoundaryFallback message="Error fetching data" />;
  }

  return (
    <div className="overflow-x-hidden p-10">
      <div className="flex flex-col gap-x-4">
        <div className="flex flex-col gap-x-6 lg:flex-row">
          <div className="flex w-full flex-col">
            <h1 className="mb-8 text-2xl font-bold text-primary-white-100">
              Upcoming Meetings
            </h1>
            <div className="pl-8">
              <MeetingsCarousel
                meetings={formattedMeetings}
                ownProfile={true}
              />
            </div>
          </div>

          <div className="mt-4 flex flex-col items-center lg:mt-0 lg:items-end">
            <div className="mb-2 flex lg:mr-8">
              <div className="flex items-center gap-x-1">
                <span className="block h-2 w-2 rounded-full bg-yellow-400"></span>
                <span className="text-xs text-white">Action Item</span>
              </div>
              <div className="ml-4 flex items-center gap-x-1">
                <span className="block h-2 w-2 rounded-full bg-purple-400"></span>
                <span className="text-xs text-white">Upcoming Meeting</span>
              </div>
            </div>
            <DashboardCalendar
              actionItemDates={actionItemDates}
              meetingDates={meetingDates}
              matchingDates={matchingDates}
            />
          </div>
        </div>

        <div>
          <h1 className="pt-4 text-2xl font-bold text-primary-white-100">
            To Do List
          </h1>

          <ToDoActionItemCollapsible
            actionItems={incompleteActionItems}
            userId={user!.id}
            refetch={refetch}
            ownProfile={true}
          />
        </div>
      </div>
    </div>
  );
};
