import { Control, FieldValues, Path } from "react-hook-form";

import { FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { Textarea } from "../ui/textArea";

export type TextareaInputProps<TFieldValues extends FieldValues, TContext> = {
  control: Control<TFieldValues, TContext>;
  name: Path<TFieldValues>;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
};

export default function TextAreaInput<
  TFieldValues extends FieldValues,
  TContext,
>({
  control,
  name,
  label,
  placeholder,
  disabled = false,
  className,
}: TextareaInputProps<TFieldValues, TContext>) {
  return (
    <FormField
      control={control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem className={className}>
          {label && <FormLabel className="text-white">{label}</FormLabel>}
          <Textarea
            className="max-h-[400px] min-h-[100px] bg-slate-300"
            {...field}
            placeholder={placeholder}
            disabled={disabled}
          />
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
