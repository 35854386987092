// Given a name, this function returns the initials of the name.
export const getInitials = (name: string | undefined) => {
  if (name) {
    const names = name.split(" ");
    if (names[0] && names[1]) {
      const initials =
        names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
      return initials;
    }
  }
};
