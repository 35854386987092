import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import type { RFeedback } from "@/types/feedback";
import { getIconByFeeling } from "@/utils/getIconByFeeling";
import { getInitials } from "@/utils/getInitials";

interface ReceivedFeedbacksTableProps {
  feedbacks: RFeedback[];
}

export const ReceivedFeedbacksTable = ({
  feedbacks,
}: ReceivedFeedbacksTableProps) => {
  const navigate = useNavigate();

  return (
    <>
      {feedbacks.length === 0 ? (
        <div className="flex h-full items-center justify-center text-slate-400">
          <p>No feedbacks to show.</p>
        </div>
      ) : (
        <div className="h-full text-white">
          <Table className="rounded-t-lg bg-gray-800">
            <TableHeader>
              <TableRow className="border-none bg-black/50 hover:bg-black/50">
                <TableHead className="w-1/3 rounded-tl-lg font-bold text-white">
                  Reviewer
                </TableHead>
                <TableHead className="w-1/3 text-center font-bold text-white">
                  Submitted
                </TableHead>
                <TableHead className="w-1/3 rounded-tr-lg text-center font-bold text-white">
                  Feeling
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="text-lg">
              {feedbacks.map((feedback) => (
                <TableRow
                  data-testid={`feedback-row${feedback.id}`}
                  key={feedback.id}
                  className="cursor-pointer hover:bg-slate-700"
                  onClick={() => {
                    navigate(
                      `/requests/${feedback.requestId}/feedbacks/${feedback.id}`,
                    );
                  }}
                >
                  {/* Reviewer cell */}
                  <TableCell className="flex items-center space-x-3">
                    <Avatar
                      className="h-8 w-8"
                      data-testid={`avatar-image-${feedback.reviewer.id}`}
                    >
                      <AvatarImage src={feedback.reviewer.avatarUrl} />
                      <AvatarFallback className="bg-gray-600 font-bold text-white">
                        {getInitials(feedback.reviewer.name)}
                      </AvatarFallback>
                    </Avatar>
                    <small>{feedback.reviewer.name}</small>
                  </TableCell>

                  {/* Submitted date cell */}
                  <TableCell className="text-center">
                    <small>
                      {feedback.submitted_at
                        ? format(
                            new Date(feedback.submitted_at),
                            "MMMM d, yyyy",
                          )
                        : "Not submitted"}
                    </small>
                  </TableCell>

                  {/* Feeling cell */}
                  <TableCell>
                    <div className="flex items-center justify-center">
                      {getIconByFeeling(feedback.feeling)}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="flex h-10 rounded-b-lg bg-black/50"></div>
        </div>
      )}
    </>
  );
};
