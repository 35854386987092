import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { DateTimeInput } from "@/components/inputs/DateTimeInput";
import MultiSelectInput from "@/components/inputs/MultiSelectInput";
import SelectInput from "@/components/inputs/SelectInput";
import TextAreaInput from "@/components/inputs/TextAreaInput";
import { TextInput } from "@/components/inputs/TextInput";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { MeetingType } from "@/types/meeting";
import type { User } from "@/types/user";
import { UpdateMeetingFormData, updateMeetingFormSchema } from "./schema";

interface UpdateMeetingFormProps {
  defaultValues?: {
    type?: MeetingType;
    targetId?: string;
    location?: string;
    date?: Date;
    participantIds?: string[];
    motive?: string;
  };
  // Id for the meeting to update
  meetingId: number;
  // Id of the user who is updating the meeting
  userId: number;
  targets: User[];
  participants: User[];
  onClose: () => void;
}

const UpdateMeetingForm = ({
  userId,
  meetingId,
  defaultValues,
  targets,
  participants,
  onClose,
}: UpdateMeetingFormProps) => {
  const queryClient = useQueryClient();

  const form = useForm<UpdateMeetingFormData>({
    resolver: zodResolver(updateMeetingFormSchema),
    defaultValues,
  });

  const { mutate: updateMeeting } = useMutation({
    mutationFn: (data: {
      meeting_type: string;
      target: number;
      location: string;
      new_meeting_date: string;
      participants: number[];
      motive: string;
    }) =>
      fetch(`/api/meetings/${meetingId}`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      }),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["meetings"] });
      onClose();
    },
  });

  const onSubmit = (data: UpdateMeetingFormData) => {
    const payload = {
      meeting_type:
        data.type === MeetingType.oneOnOne
          ? "1:1"
          : data.type === MeetingType.feedback
            ? "feedback"
            : data.type === MeetingType.performance_review
              ? "performance_review"
              : "",
      target: parseInt(data.targetId, 10),
      location: data.location,
      new_meeting_date: data.date.toISOString(),
      participants: data.participantIds.map((id) => parseInt(id, 10)),
      motive: data.motive,
    };

    updateMeeting(payload);
  };

  const options = participants
    .filter(
      (participant, index, self) =>
        index === self.findIndex((p) => p.id === participant.id),
    )
    .map((participant) => ({
      label: participant.name,
      value: participant.id.toString(),
    }));

  const [currentTarget, setCurrentTarget] = useState<string>(
    form.getValues("targetId"),
  );

  const targetId = form.watch("targetId");
  const participantIds = form.watch("participantIds");

  useEffect(() => {
    const participantIds = form.getValues("participantIds");

    // If there's a new targetId selected, update participantIds
    if (targetId && targetId !== currentTarget) {
      const updatedParticipantIds = participantIds
        .filter((id) => id !== currentTarget) // Remove the previous targetId
        .concat(targetId); // Add the new targetId

      form.setValue("participantIds", updatedParticipantIds); // Update participantIds
    }
    setCurrentTarget(targetId);
  }, [targetId, form]);

  useEffect(() => {
    if (currentTarget && !participantIds.includes(currentTarget)) {
      const updatedParticipantIds = [...participantIds, currentTarget];
      form.setValue("participantIds", updatedParticipantIds);
    }
    if (!participantIds.includes(userId.toString())) {
      const updatedParticipantIds = [...participantIds, userId.toString()];
      form.setValue("participantIds", updatedParticipantIds);
    }
  }, [participantIds, form, currentTarget]);

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <SelectInput
          control={form.control}
          name="type"
          items={Object.values(MeetingType)}
          valueAccessor={(type) => type}
          labelAccessor={(type) => type}
          label="Meeting type"
          placeholder="Select a type"
        />
        <TextInput
          control={form.control}
          name="location"
          label="Location"
          placeholder="Location"
        />
        <SelectInput
          className="max-h-[80vh] overflow-y-auto"
          control={form.control}
          name="targetId"
          label="Meeting target"
          items={targets}
          placeholder="Select a target"
          valueAccessor={(target) => target.id.toString()}
          labelAccessor={(target) => target.name}
        />
        <MultiSelectInput
          key={JSON.stringify(participantIds)}
          control={form.control}
          name="participantIds"
          options={options}
          label="Meeting participants"
          placeholder="Select participants"
          defaultValue={participantIds}
        />
        <DateTimeInput control={form.control} name="date" label="Date" />
        <TextAreaInput control={form.control} name="motive" label="Motive" />
        <div className="flex justify-end gap-x-2">
          <Button className="text-white" variant="outline" type="submit">
            Save
          </Button>
          <Button
            className="text-white"
            variant="outline"
            type="button"
            onClick={() => onClose()}
          >
            Discard
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default UpdateMeetingForm;
