import { Frown, Meh, Smile } from "lucide-react";

export const getIconByFeeling = (feeling: string | null) => {
  switch (feeling) {
    case "Good":
      return <Smile className="text-green-500" />;
    case "Neutral":
      return <Meh className="text-yellow-500" />;
    case "Bad":
      return <Frown className="text-red-500" />;
    default:
      return null;
  }
};
