import { useQuery } from "@tanstack/react-query";
import { format, formatDistanceToNow } from "date-fns";
import { useParams } from "react-router-dom";

import TargetUserCard from "@/components/cards/TargetUserCard";
import LoadingScreen from "@/components/LoadingScreen";
import { ErrorBoundaryFallback } from "@/screens/ErrorBoundaryFallback";
import { useUserStore } from "@/stores";
import { FeedbackWithRequest } from "@/types/feedback";
import { getIconByFeeling } from "@/utils/getIconByFeeling";

export const FeedbackDetails = () => {
  const { user } = useUserStore();

  const { id } = useParams();

  const {
    data: response,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["feedbacks", id],
    queryFn: (): Promise<{ data: FeedbackWithRequest }> =>
      fetch(`/api/feedbacks/${id}`, {
        method: "GET",
        headers: {
          token: user!.id.toString(),
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
    enabled: !!id,
  });

  const feedbackRequest = response?.data;

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) return <ErrorBoundaryFallback message={error.message} />;

  if (!feedbackRequest)
    return <ErrorBoundaryFallback message="Error fetching meeting data" />;

  const target = feedbackRequest.request.target;
  const reviewer = feedbackRequest.reviewer;

  return (
    <div className="space-y-4 p-10 text-white">
      <h1 className="text-5xl font-bold">Feedback detail</h1>
      <div className="flex flex-col flex-wrap gap-x-5 xl:flex-row">
        <div>
          <h1 className="py-2 text-2xl font-bold">Reviewer</h1>
          <TargetUserCard
            id={reviewer.id}
            name={reviewer.name}
            email={reviewer.email}
            avatarUrl={reviewer.avatarUrl}
            hours={reviewer.hours}
            jobTitle={reviewer.jobTitle}
            lastPosition={reviewer.lastPosition}
          />
        </div>
        <div>
          <h1 className="py-2 text-2xl font-bold">Target</h1>
          <TargetUserCard
            id={target.id}
            name={target.name}
            email={target.email}
            avatarUrl={target.avatarUrl}
            hours={target.hours}
            jobTitle={target.jobTitle}
            lastPosition={target.lastPosition}
          />
        </div>
      </div>
      <div>
        <h1 className="py-2 text-2xl font-bold">Submitted</h1>
        <div className="flex flex-wrap gap-x-2">
          <h1 className="text-xl">
            {format(
              new Date(feedbackRequest.submitted_at!),
              "EEEE, d MMMM, yyyy",
            )}
          </h1>
          <h1 className="text-xl">
            (
            {formatDistanceToNow(new Date(feedbackRequest.submitted_at!), {
              addSuffix: true,
            })}
            )
          </h1>
        </div>
      </div>

      <div className="space-y-2 pt-2">
        <h1 className="text-2xl font-bold">
          {feedbackRequest.request.question}
        </h1>
        <div className="flex flex-wrap gap-x-1 text-xl">
          <strong>Overall feeling:</strong>
          {feedbackRequest.feeling && (
            <p>
              {" "}
              {feedbackRequest.feeling.charAt(0).toUpperCase() +
                feedbackRequest.feeling.slice(1)}
            </p>
          )}
          {getIconByFeeling(feedbackRequest.feeling)}
        </div>
        <div>
          <p className="text-xl">{feedbackRequest.content}</p>
        </div>
      </div>
    </div>
  );
};
