import { useQuery } from "@tanstack/react-query";
import { format, formatDistanceToNow } from "date-fns";
import { Check, Clock } from "lucide-react";
import { useParams } from "react-router-dom";

import SmallUserCard from "@/components/cards/SmallUserCard";
import { FeedbackCarousel } from "@/components/carousels/FeedbackCarousel";
import LoadingScreen from "@/components/LoadingScreen";
import { ErrorBoundaryFallback } from "@/screens/ErrorBoundaryFallback";
import { useUserStore } from "@/stores";
import { RequestWithFeedbacks } from "@/types/request";

export const RequestDetails = () => {
  const { id } = useParams();
  const { user } = useUserStore();

  const {
    data: response,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["request", id],
    queryFn: (): Promise<{ data: RequestWithFeedbacks }> =>
      fetch(`/api/requests/${id}`, {
        method: "GET",
        headers: {
          token: user!.id.toString(),
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
    enabled: !!id,
  });

  const request = response?.data;

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error || !request) {
    return <ErrorBoundaryFallback message="Error fetching request data" />;
  }

  return (
    <div className="flex flex-col space-y-4 p-10 text-white">
      <h1 className="text-5xl font-bold">
        Requested feedback for {request.target.name}
      </h1>

      <div>
        <h1 className="text-left text-2xl font-bold">Reviewers</h1>
        <div className="flex flex-wrap">
          {request.feedbacks.map((feedback) => (
            <div
              key={feedback.reviewer.id}
              className="flex flex-row items-center py-2 pl-0 pr-4"
            >
              <SmallUserCard
                id={feedback.reviewer.id}
                name={feedback.reviewer.name}
                email={feedback.reviewer.email}
                avatarUrl={feedback.reviewer.avatarUrl}
                icon={
                  feedback.content ? (
                    <Check size={17} strokeWidth={3} color="green" />
                  ) : (
                    <Clock size={16} color="yellow" />
                  )
                }
              />
            </div>
          ))}
        </div>
      </div>

      <div>
        <h1 className="text-2xl font-bold">Deadline</h1>
        <div className="flex flex-wrap gap-x-2">
          <h1 className="text-xl">
            {format(request.due_date, "EEEE, d MMMM, yyyy")}
          </h1>
          <h1 className="text-xl">
            (
            {formatDistanceToNow(request.due_date, {
              addSuffix: true,
            })}
            )
          </h1>
        </div>
      </div>

      <div>
        <h1 className="text-2xl font-bold">Question</h1>
        <h1 className="text-xl">{request.question}</h1>
      </div>

      <h1 className="text-2xl font-bold">Feedbacks received</h1>
      <FeedbackCarousel
        feedbacks={request.feedbacks.filter(
          (feedback) => feedback.content !== null,
        )}
      />
    </div>
  );
};
