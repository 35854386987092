import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { EllipsisVertical } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { User } from "@/stores";
import { Round } from "@/types/round";
import { Button } from "@/ui";
import { DataTable } from "../ui/data-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

interface RoundTableProps {
  rounds: Round[];
  user: User;
}

export const RoundsTable = ({ rounds, user }: RoundTableProps) => {
  const navigate = useNavigate();

  const columns: ColumnDef<Round>[] = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Start",
      cell: ({ row }) => {
        const round = row.original;
        return format(new Date(round.start_date), "dd/MM/yyyy");
      },
    },
    {
      header: "End",
      cell: ({ row }) => {
        const round = row.original;
        return format(new Date(round.end_date), "dd/MM/yyyy");
      },
    },
    {
      header: "Actions",
      cell: ({ row }) => {
        const round = row.original;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger className="cursor-pointer" asChild>
              <Button variant="tertiary" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <EllipsisVertical className="w-6" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-slate-200">
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/rounds/${round.id}/received-feedbacks`);
                }}
              >
                Received Feedbacks
              </DropdownMenuItem>
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/rounds/${round.id}/sent-feedbacks`);
                }}
              >
                Sent Feedbacks
              </DropdownMenuItem>
              {user.role === "manager" && (
                <DropdownMenuItem
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/rounds/${round.id}/requests`);
                  }}
                >
                  Requests
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return <DataTable columns={columns} data={rounds} />;
};
