import { z } from "zod";

export const newActionItemFormSchema = z.object({
  title: z.string(),
  description: z.string(),
  due_date: z.date(),
  assigned: z.string().regex(/^\d+$/),
});

export type NewActionItemFormData = z.infer<typeof newActionItemFormSchema>;
