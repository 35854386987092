import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import LoadingScreen from "@/components/LoadingScreen";
import { RequestedFeedbacksTable } from "@/components/tables/RequestedFeedbacksTable";
import { SentFeedbacksTable } from "@/components/tables/SentFeedbacksTable";
import { ErrorBoundaryFallback } from "@/screens/ErrorBoundaryFallback";
import { useUserStore } from "@/stores";
import { RFeedbacks } from "@/types/round";
import { separateRequestsByFeedbacks } from "@/utils/separateRequestByFeedback";

export const SentFeedbacks = () => {
  const { user } = useUserStore();
  const { id } = useParams();
  const userId = user!.id;

  const {
    data: sent_feedbacks,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["sentFeedbacks", id],
    queryFn: (): Promise<RFeedbacks> => {
      return fetch(`/api/rounds/${id}/sent-feedbacks`, {
        method: "GET",
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => response.data);
    },
    enabled: !!id,
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) return <ErrorBoundaryFallback message={error.message} />;

  if (!sent_feedbacks)
    return <ErrorBoundaryFallback message="No feedbacks found" />;

  const allFeedbacks = sent_feedbacks.requests.flatMap((requestGroup) =>
    requestGroup.flatMap((request) => request),
  );

  const { requestedFeedbacks, sentFeedbacks } =
    separateRequestsByFeedbacks(allFeedbacks);

  return (
    <div className="p-10">
      <div className="mb-6 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-primary-white-100">Feedbacks</h1>
        <h1 className="text-2xl font-bold text-primary-white-100">
          Round: {sent_feedbacks.round.name}
        </h1>
      </div>

      <h2 className="mb-2 text-xl font-semibold text-primary-white-100">
        Requests
      </h2>
      <RequestedFeedbacksTable requests={requestedFeedbacks} />
      <h2 className="mb-2 mt-8 text-xl font-semibold text-primary-white-100">
        Sent
      </h2>
      <SentFeedbacksTable requests={sentFeedbacks} />
    </div>
  );
};
