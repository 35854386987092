import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useForm } from "react-hook-form";

import { DateTimeInput } from "@/components/inputs/DateTimeInput";
import SelectInput from "@/components/inputs/SelectInput";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { MeetingType } from "@/types/meeting";
import type { User } from "@/types/user";
import { MeetingFilterFormData, meetingFilterFormSchema } from "./schema";

interface MeetingFilterFormProps {
  targets: User[];
  setGetMeetingsEndpoint: React.Dispatch<React.SetStateAction<string>>;
}

export const MeetingFilterForm = ({
  targets,
  setGetMeetingsEndpoint,
}: MeetingFilterFormProps) => {
  const form = useForm<MeetingFilterFormData>({
    resolver: zodResolver(meetingFilterFormSchema),
    defaultValues: {},
  });

  const onSubmit = (data: MeetingFilterFormData) => {
    const params = new URLSearchParams();

    if (data.target) params.append("target", data.target);
    if (data.creator) params.append("creator", data.creator);
    if (data.participant) params.append("participant", data.participant);
    if (data.type) {
      const meeting_type =
        data.type === MeetingType.oneOnOne
          ? "1:1"
          : data.type === MeetingType.feedback
            ? "feedback"
            : "performance_review";
      params.append("type", meeting_type);
    }
    if (data.from) params.append("from", format(data.from, "yyyy-MM-dd"));
    if (data.to) params.append("to", format(data.to, "yyyy-MM-dd"));

    setGetMeetingsEndpoint(`/api/meetings/?${params.toString()}`);
  };

  const clearFilters = () => {
    form.reset({
      target: "",
      participant: "",
      type: "",
      creator: "",
    });
    setGetMeetingsEndpoint(`/api/meetings/`);
  };

  const uniqueTargets = targets.filter(
    (user, index, self) => index === self.findIndex((u) => u.id === user.id),
  );

  return (
    <Form {...form}>
      <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex flex-row gap-x-4">
          <SelectInput
            control={form.control}
            name="target"
            label="Meeting target"
            items={uniqueTargets}
            className="flex-1"
            placeholder="Select the target"
            valueAccessor={(target) => target.id.toString()}
            labelAccessor={(target) => target.name}
          />
          <SelectInput
            control={form.control}
            name="creator"
            label="Meeting creator"
            items={uniqueTargets}
            className="flex-1"
            placeholder="Select the creator"
            valueAccessor={(target) => target.id.toString()}
            labelAccessor={(target) => target.name}
          />
          <SelectInput
            control={form.control}
            name="participant"
            label="Participant"
            items={uniqueTargets}
            className="flex-1"
            placeholder="Select a participant"
            valueAccessor={(target) => target.id.toString()}
            labelAccessor={(target) => target.name}
          />
          <SelectInput
            control={form.control}
            name="type"
            items={Object.values(MeetingType)}
            className="flex-1"
            valueAccessor={(type) => type}
            labelAccessor={(type) => type}
            label="Meeting type"
            placeholder="Select a type"
          />
          <div className="flex items-end">
            <DateTimeInput
              control={form.control}
              name="from"
              label="From date"
            />
          </div>
          <div className="flex items-end">
            <DateTimeInput control={form.control} name="to" label="To date" />
          </div>
        </div>
        <div className="flex items-center justify-end gap-x-2">
          <Button className="text-white" variant="outline" type="submit">
            Apply
          </Button>
          <Button
            className="text-white"
            variant="outline"
            onClick={clearFilters}
          >
            Clear
          </Button>
        </div>
      </form>
    </Form>
  );
};
