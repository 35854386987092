import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import type { RequestWithFeedbacks } from "@/types/request";
import { getInitials } from "@/utils/getInitials";

interface RequestedFeedbacksTableProps {
  requests: RequestWithFeedbacks[];
}

export const RequestedFeedbacksTable = ({
  requests,
}: RequestedFeedbacksTableProps) => {
  const navigate = useNavigate();

  const currentDate = new Date();

  return (
    <>
      {requests.length === 0 ? (
        <div className="flex h-full items-center justify-center text-slate-400">
          <p>No requests to show.</p>
        </div>
      ) : (
        <div className="rounded-md bg-gray-800 text-white">
          <Table className="rounded-t-lg bg-gray-800">
            <TableHeader>
              <TableRow className="border-none bg-black/50 hover:bg-black/50">
                <TableHead className="w-1/3 rounded-tl-lg font-bold text-white">
                  Target
                </TableHead>
                <TableHead className="w-1/3 text-center font-bold text-white">
                  Deadline
                </TableHead>
                <TableHead className="w-1/3 rounded-tr-lg text-center font-bold text-white">
                  Mandatory
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="text-lg">
              {requests.map((request) =>
                request.feedbacks.map((feedback) => (
                  <TableRow
                    data-testid={`feedback-row${feedback.id}`}
                    key={`${feedback.id}`}
                    className="cursor-pointer hover:bg-slate-700"
                    onClick={() => {
                      if (currentDate < new Date(request.due_date)) {
                        navigate(`/requests/${feedback.id}/new-feedback`);
                      }
                    }}
                  >
                    {/* Target cell */}
                    <TableCell className="mr-8 flex items-center space-x-3">
                      <Avatar
                        className="h-8 w-8"
                        data-testid={`avatar-image-${request.target.id}`}
                      >
                        <AvatarImage src={request.target.avatarUrl} />
                        <AvatarFallback className="bg-gray-600 font-bold text-white">
                          {getInitials(request.target.name)}
                        </AvatarFallback>
                      </Avatar>
                      <small>{request.target.name}</small>
                    </TableCell>

                    {/* Deadline cell */}
                    <TableCell className="text-center">
                      {request.due_date ? (
                        currentDate < new Date(request.due_date) ? (
                          <small>
                            {format(new Date(request.due_date), "MMMM d, yyyy")}
                          </small>
                        ) : (
                          <small className="text-red-500">
                            {format(new Date(request.due_date), "MMMM d, yyyy")}{" "}
                            (Overdue)
                          </small>
                        )
                      ) : (
                        <small>No deadline.</small>
                      )}
                    </TableCell>
                    <TableCell className="text-center">
                      <small>{request.mandatory ? "Yes" : "No"}</small>
                    </TableCell>
                  </TableRow>
                )),
              )}
            </TableBody>
          </Table>
          <div className="flex h-10 rounded-b-lg bg-black/50"></div>
        </div>
      )}
    </>
  );
};
