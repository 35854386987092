import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import LoadingScreen from "@/components/LoadingScreen";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useUserStore } from "@/stores";
import type { User } from "@/types/user";
import { ErrorBoundaryFallback } from "../ErrorBoundaryFallback";

export const EmployeeList = () => {
  const { user } = useUserStore();
  const navigate = useNavigate();
  const email = user!.email;

  const {
    data: response,
    isLoading,
    error,
  } = useQuery({
    queryFn: (): Promise<{ data: User[] }> =>
      fetch(`/api/users/${email}/employees`).then((res) => res.json()),
    queryKey: ["employees"],
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <div className="error" data-testid={"fetch-error"}>
        <ErrorBoundaryFallback message="Error fetching" />
      </div>
    );
  }

  const employees = response?.data ?? [];

  return (
    <div className="grid grid-cols-4 gap-8 p-8">
      {employees.map((employee) => (
        <Card
          key={employee.id}
          className="flex transform flex-col items-center justify-between border border-gray-800 bg-gray-800 p-5 text-center transition duration-200 hover:scale-[1.03] hover:bg-slate-700 hover:shadow-lg"
        >
          <div
            onClick={() => navigate(`/employees/${employee.id}`)}
            className="flex cursor-pointer flex-col items-center"
          >
            <CardHeader className="flex flex-col items-center">
              <Avatar
                className="mb-3 h-24 w-24"
                data-testid={`avatar-image-${employee.id}`}
              >
                <AvatarImage src={employee.avatarUrl} />
                <AvatarFallback className="bg-gray-600 text-white">
                  {employee.name.charAt(0)}
                </AvatarFallback>
              </Avatar>
              <CardTitle className="text-lg font-bold text-white">
                {employee.name}
              </CardTitle>
            </CardHeader>
            <CardContent className="text-center">
              <CardDescription className="hidden text-sm text-gray-400 md:block">
                {employee.email}
              </CardDescription>
            </CardContent>
          </div>
        </Card>
      ))}
    </div>
  );
};
