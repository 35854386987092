import { ReactNode } from "react";
import { type Control, type FieldValues, type Path } from "react-hook-form";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export type SelectInputProps<
  TFieldValues extends FieldValues,
  TContext,
  TData,
> = {
  control: Control<TFieldValues, TContext>;
  name: Path<TFieldValues>;
  label?: string;
  items: TData[];
  placeholder?: string;
  disabled?: boolean;
  valueAccessor: (item: TData) => string;
  keyAccessor?: (item: TData) => string;
  labelAccessor?: (item: TData) => ReactNode;
  onValueChange?: (value: string) => void;
  className?: string;
};

export default function SelectInput<
  TFieldValues extends FieldValues,
  TContext,
  TData,
>({
  control,
  name,
  label,
  items,
  placeholder,
  disabled = false,
  valueAccessor,
  keyAccessor = valueAccessor,
  labelAccessor = valueAccessor,
  onValueChange,
  className,
}: SelectInputProps<TFieldValues, TContext, TData>) {
  return (
    <FormField
      control={control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem className={className}>
          {label && <FormLabel className="text-white">{label}</FormLabel>}
          <Select
            disabled={disabled}
            onValueChange={(value) => {
              field.onChange(value);

              return onValueChange?.(value);
            }}
            defaultValue={field.value}
            value={field.value}
          >
            <FormControl>
              <SelectTrigger className="bg-slate-300">
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {items.map((d) => (
                <SelectItem key={keyAccessor(d)} value={valueAccessor(d)}>
                  {labelAccessor(d)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
