import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

interface TopicCardProps {
  name: string;
  creator_name: string;
  main_topic: boolean;
}

const TopicCard = ({ name, creator_name, main_topic }: TopicCardProps) => {
  return (
    <Card className="flex h-44 w-full flex-col overflow-hidden border-none bg-gray-800 text-center text-white">
      {/* Header section */}
      <CardHeader className="flex flex-col items-center bg-black/50 p-2">
        {/* Fixed height for the header */}
        <CardTitle
          className={`${main_topic ? "text-green-400" : "text-purple-400"} text-lg`}
        >
          {main_topic ? "Main Topic" : "Sub Topic"}
        </CardTitle>
        <h1 className="text-xs text-gray-400"> by {creator_name}</h1>
      </CardHeader>
      {/* Content section */}
      <CardContent className="flex-1 overflow-y-auto px-4 py-2">
        {/* Allow overflow */}
        <h1 className="text-left text-sm text-gray-300">{name}</h1>
      </CardContent>
    </Card>
  );
};

export default TopicCard;
