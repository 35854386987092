"use client";

import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import type { Control, FieldValues, Path } from "react-hook-form";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { TimePickerDemo } from "@/components/ui/time-picker/time-picker-demo";
import { cn } from "@/lib/utils";

export interface DateTimeInputProps<
  TFieldValues extends FieldValues,
  TContext,
> {
  control: Control<TFieldValues, TContext>;
  name: Path<TFieldValues>;
  label?: string;
  removetime?: boolean;
}

export function DateTimeInput<TFieldValues extends FieldValues, TContext>({
  control,
  name,
  label,
  removetime,
}: DateTimeInputProps<TFieldValues, TContext>) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className="flex flex-col">
          {label && (
            <FormLabel className="text-left text-white">{label}</FormLabel>
          )}
          <Popover>
            <FormControl>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    "max-w-[280px] justify-start bg-slate-300 text-left font-normal",
                    !field.value && "text-muted-foreground",
                    fieldState.invalid,
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {field.value ? (
                    removetime ? (
                      format(field.value, "PPP")
                    ) : (
                      format(field.value, "PPP HH:mm:ss")
                    )
                  ) : (
                    <span>Pick a date</span>
                  )}
                </Button>
              </PopoverTrigger>
            </FormControl>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={field.value}
                onSelect={field.onChange}
                initialFocus
              />
              {!removetime && (
                <div className="border-t border-border p-3">
                  <TimePickerDemo setDate={field.onChange} date={field.value} />
                </div>
              )}
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
