import { type Control, type FieldValues, type Path } from "react-hook-form";

import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

export type RadioGroupInputProps<
  TFieldValues extends FieldValues,
  TContext,
  TData,
> = {
  control: Control<TFieldValues, TContext>;
  name: Path<TFieldValues>;
  items: TData[];
  className?: string;
  valueAccessor: (item: TData) => any;
  labelAccessor: (item: TData) => string;
};

export default function RadioGroupInput<
  TFieldValues extends FieldValues,
  TContext,
  TData,
>({
  control,
  name,
  items,
  className,
  valueAccessor,
  labelAccessor,
}: RadioGroupInputProps<TFieldValues, TContext, TData>) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormControl>
            <RadioGroup
              className="flex space-x-4"
              {...field}
              onValueChange={(value) => {
                field.onChange(value);
              }}
            >
              {items.map((item, i) => (
                <div className="flex items-center space-x-1" key={i}>
                  <RadioGroupItem
                    className="bg-slate-300"
                    id={valueAccessor(item)}
                    value={valueAccessor(item)}
                  />
                  <Label className="text-white" htmlFor={valueAccessor(item)}>
                    {labelAccessor(item)}
                  </Label>
                </div>
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
