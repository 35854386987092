import type { RFeedback } from "@/types/feedback";

export const separateFeedbacks = (
  feedbacks: RFeedback[],
): { pendingFeedbacks: RFeedback[]; receivedFeedbacks: RFeedback[] } => {
  const pendingFeedbacks: RFeedback[] = [];
  const receivedFeedbacks: RFeedback[] = [];

  feedbacks.forEach((feedback) => {
    if (Object.keys(feedback.reviewer).length > 0) {
      if (feedback.content === null) {
        pendingFeedbacks.push(feedback);
      } else {
        receivedFeedbacks.push(feedback);
      }
    }
  });

  return { pendingFeedbacks, receivedFeedbacks };
};
