import type { RequestWithFeedbacks } from "@/types/request"; // Asegúrate de importar correctamente el tipo

export const separateRequestsByFeedbacks = (
  requests: RequestWithFeedbacks[],
): {
  requestedFeedbacks: RequestWithFeedbacks[];
  sentFeedbacks: RequestWithFeedbacks[];
} => {
  const requestedFeedbacks: RequestWithFeedbacks[] = [];
  const sentFeedbacks: RequestWithFeedbacks[] = [];

  requests.forEach((request) => {
    const { feedbacks } = request;

    if (feedbacks.length > 0) {
      const pendingFeedbacks = feedbacks.filter(
        (feedback) => feedback.content === null,
      );
      const sentFeedbacksList = feedbacks.filter(
        (feedback) => feedback.content !== null,
      );

      if (pendingFeedbacks.length > 0) {
        requestedFeedbacks.push({
          ...request,
          feedbacks: pendingFeedbacks,
        });
      }

      if (sentFeedbacksList.length > 0) {
        sentFeedbacks.push({
          ...request,
          feedbacks: sentFeedbacksList,
        });
      }
    }
  });

  return { requestedFeedbacks, sentFeedbacks };
};
