import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Plus } from "lucide-react";

import NewRoundForm from "@/components/forms/NewRoundForm";
import LoadingScreen from "@/components/LoadingScreen";
import { RoundsTable } from "@/components/tables/RoundsTable";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useUserStore } from "@/stores";
import { Round } from "@/types/round";
import { ErrorBoundaryFallback } from "../ErrorBoundaryFallback";

export const RoundsScreen = () => {
  const { user } = useUserStore();
  const [isOpen, setIsOpen] = useState(false);
  const userId = user!.id;

  const {
    data: rounds,
    isLoading,
    error,
  } = useQuery({
    queryFn: (): Promise<Round[]> =>
      fetch(`/api/rounds`, {
        method: "GET",
        headers: {
          token: user!.id.toString(),
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => res.data),
    queryKey: ["rounds"],
  });

  if (isLoading) return <LoadingScreen />;

  if (error) return <ErrorBoundaryFallback message={error.message} />;

  if (!rounds) return <ErrorBoundaryFallback message="No rounds found" />;

  return (
    <section className="p-10 text-white">
      <Dialog onOpenChange={setIsOpen} open={isOpen} defaultOpen={isOpen}>
        <div className="mb-4 flex items-center justify-between">
          <h1 className="text-whitem relative text-2xl font-bold">Rounds</h1>
          {user!.role === "manager" && (
            <Button
              variant="outline"
              size="icon"
              className="ml-auto text-xl text-white"
              onClick={() => setIsOpen(true)}
              aria-label="Create a new meeting"
            >
              <Plus />
            </Button>
          )}
        </div>
        <RoundsTable rounds={rounds} user={user!} />
        <DialogContent className="w-auto max-w-full overflow-visible border-0 bg-primary transition">
          <DialogHeader>
            <DialogTitle className="text-white">New Round</DialogTitle>
          </DialogHeader>
          <NewRoundForm userId={userId} onClose={() => setIsOpen(false)} />
        </DialogContent>
      </Dialog>
    </section>
  );
};
