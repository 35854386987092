import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { Logo } from "@/components";
import { EmployeeCombobox } from "@/components/comboboxes/employeeCombobox";
import LoadingScreen from "@/components/LoadingScreen";
import { useUserStore } from "@/stores";
import type { User } from "@/types/user";

export const Login = () => {
  const { setUser } = useUserStore();
  const navigate = useNavigate();

  // Used to store the currently selected employee
  const [selectedEmployee, setSelectedEmployee] = useState<User | null>(null);

  // Fetch the list of employees
  const {
    data: response,
    isLoading,
    error,
  } = useQuery({
    queryFn: (): Promise<{ data: User[] }> =>
      fetch(`/api/users/`).then((res) => res.json()),
    queryKey: ["users"],
  });

  const { isPending: isLoggingIn, mutate: logIn } = useMutation({
    mutationFn: (user: User): Promise<User[]> =>
      fetch(`/api/users/${user.email}/employees`)
        .then((res) => res.json())
        .then((res) => res.data),

    onSuccess: (data, user) => {
      setUser({
        id: user.id,
        email: user.email,
        name: user.name,
        picture: user.avatarUrl,
        role: data.length > 0 ? "manager" : "user",
      });

      navigate("/");
    },
  });

  if (isLoading || isLoggingIn) {
    return (
      <div data-testid="loading">
        <LoadingScreen data-testid="loading" />
      </div>
    );
  }

  if (error) {
    return <div className="error">Error: error fetching</div>;
  }

  const users = response?.data ?? [];

  const uniqueUsers = users.filter(
    (user, index, self) => index === self.findIndex((u) => u.id === user.id),
  );

  // Sets the selected employee
  const handleEmployeeSelect = (employee: User | null) => {
    if (employee) {
      setSelectedEmployee(employee);
    }
  };

  // Saves the selected employee details in session storage
  const handleLogIn = async () => {
    if (selectedEmployee) {
      logIn(selectedEmployee);
    }
  };

  return (
    <div className="flex h-screen grow flex-col items-center justify-center gap-9 bg-gray-800 px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Logo className="mx-auto h-16 w-auto" />

        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
          Sign in to your account
        </h2>
      </div>
      {/* Combobox to select an employee */}
      <EmployeeCombobox
        employees={uniqueUsers}
        onSelect={handleEmployeeSelect}
      />

      {/* Sign-in button that triggers the login process */}
      <button
        type="button"
        className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        disabled={!selectedEmployee} // Disable the button if no employee is selected
        onClick={handleLogIn}
      >
        Sign in
      </button>
    </div>
  );
};
