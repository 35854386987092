import React from "react";
import { AlertCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Logo } from "@/components";

interface ErrorBoundaryFallbackProps {
  message: string;
  goBack?: boolean;
}

export const ErrorBoundaryFallback: React.FC<ErrorBoundaryFallbackProps> = ({
  message,
  goBack,
}) => {
  const navigate = useNavigate();
  const handleRetry = () => {
    navigate(0); // Reload
  };
  const handleGoBack = () => {
    navigate(-1); // Go back
  };

  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="mx-auto w-full max-w-md rounded-xl bg-black bg-opacity-30 p-8 text-center">
        <div className="mb-8 flex items-center justify-center space-x-3">
          <Logo />
        </div>
        <div className="flex items-center justify-center space-x-3 text-white">
          <AlertCircle className="h-6 w-6" />
          <h2 className="text-2xl font-semibold">Something went wrong</h2>
        </div>
        <p className="mt-4 text-lg text-gray-200">{message}</p>
        {goBack ? (
          <button
            onClick={handleGoBack}
            className="mt-6 rounded-lg border border-gray-300 bg-white px-4 py-2 text-gray-800 transition-opacity duration-200 ease-in-out hover:bg-gray-100 hover:opacity-90 focus:outline-none"
          >
            Go Back
          </button>
        ) : (
          <button
            onClick={handleRetry}
            className="mt-6 rounded-lg border border-gray-300 bg-white px-4 py-2 text-gray-800 transition-opacity duration-200 ease-in-out hover:bg-gray-100 hover:opacity-90 focus:outline-none"
          >
            Try Again
          </button>
        )}
      </div>
    </div>
  );
};
