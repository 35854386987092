import { Outlet } from "react-router-dom";

import { useUserStore } from "@/stores";
import { Navbar } from "./Navbar";

export const Layout = () => {
  const { user } = useUserStore();

  return (
    <div className="h-screen flex-col overflow-hidden bg-gray-900 md:flex md:flex-row">
      {user && <Navbar />}
      <main className="h-full grow overflow-y-auto">
        <Outlet />
      </main>
    </div>
  );
};
