export const ROUTES = {
  login: "/login",
  home: "/",
  employeeList: "/employees",
  userProfile: "/profile",
  employeeId: "/employees/:id",
  createNote: "/notes/new",
  meetingId: "/meetings/:id",
  createMeeting: "/meetings/new",
  notFound: "*",
  meetings: "/meetings",
  notes: "/notes",
  noteId: "/notes/:id",
  actionItem: "/action-items",
  requests: "/rounds/:id/requests",
  requestId: "/requests/:id",
  feedbackId: "/requests/:requestId/feedbacks/:id",
  receivedFeedbacks: "/rounds/:id/received-feedbacks",
  submitFeedback: "/requests/:id/new-feedback",
  sentFeedbacks: "/rounds/:id/sent-feedbacks",
  createRequest: "/rounds/:id/requests/new",
  rounds: "/rounds",
} as const;

export const MODAL_ROUTES = {
  exampleModal: "/example-modal",
  userForm: "/user-form",
  notesModal: "/notes-modal",
} as const;
