import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import RadioGroupInput from "@/components/inputs/RadioGroupInput";
import SelectInput from "@/components/inputs/SelectInput";
import TextAreaInput from "@/components/inputs/TextAreaInput";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Meeting } from "@/types/meeting";
import { formatMeetingType } from "@/utils/formatMeetingType";
import { NewNoteFormData, newNoteFormSchema } from "./schema";

interface NewNoteFormProps {
  // The meetings prop is an array of Meeting objects.
  meetings: Meeting[];
  // The defaultValues prop is an object that contains the default values for the form fields.
  defaultValues?: {
    meeting_id?: string;
    content?: string;
    private?: "true" | "false";
  };
  // The writer id prop is the id of the user who is creating the note.
  writerId: number;
}

const NewNoteForm = ({
  meetings,
  defaultValues,
  writerId,
}: NewNoteFormProps) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const form = useForm<NewNoteFormData>({
    resolver: zodResolver(newNoteFormSchema),
    defaultValues,
  });

  const { isPending, mutate: createNote } = useMutation({
    mutationFn: (data: {
      meeting_id: number;
      content: string;
      private: boolean;
    }) =>
      fetch("/api/notes", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          token: writerId.toString(),
          "Content-Type": "application/json",
        },
      }),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["user", writerId] });
      navigate(`/notes`);
    },
  });

  const onSubmit = (data: NewNoteFormData) => {
    createNote({
      meeting_id: parseInt(data.meeting_id, 10),
      content: data.content,
      private: data.private === "true",
    });
  };

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <SelectInput
          control={form.control}
          name="meeting_id"
          items={meetings}
          label="Meeting"
          valueAccessor={(meeting) => meeting.id.toString()}
          labelAccessor={(meeting) =>
            `${formatMeetingType(meeting.meeting_type)} - ${meeting.target.name} - ${format(meeting.meeting_date, "dd/MM/yyyy")}`
          }
          placeholder="Select a meeting"
        />
        <TextAreaInput control={form.control} name="content" label="Content" />
        <div className="mt-2 flex items-center justify-between">
          <RadioGroupInput
            control={form.control}
            name="private"
            items={[
              { value: "true", label: "Private" },
              { value: "false", label: "Public" },
            ]}
            valueAccessor={(item) => item.value}
            labelAccessor={(item) => item.label}
          />
          <div className="flex items-center gap-x-2">
            <Button
              className="text-white"
              variant="outline"
              type="submit"
              disabled={isPending}
            >
              Save
            </Button>
            <Button
              className="text-white"
              variant="outline"
              onClick={() => {
                navigate("/notes");
              }}
            >
              Discard
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default NewNoteForm;
