import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { DateTimeInput } from "@/components/inputs/DateTimeInput";
import MultiSelectInput from "@/components/inputs/MultiSelectInput";
import SelectInput from "@/components/inputs/SelectInput";
import { TextInput } from "@/components/inputs/TextInput";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { useUserStore } from "@/stores/useUserStore";
import { MeetingType } from "@/types/meeting";
import type { User } from "@/types/user";
import { newMeetingFormSchema, type NewMeetingFormData } from "./schema";

interface NewMeetingFormProps {
  targets: User[];
  participants: User[];
}

export const NewMeetingForm = ({
  targets,
  participants,
}: NewMeetingFormProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useUserStore();
  const userId = user!.id;

  //Access the query parameters
  const location = useLocation(); //Access the current URL
  const queryParams = new URLSearchParams(location.search);
  const targetQueryParam = queryParams.get("target");

  const form = useForm<NewMeetingFormData>({
    resolver: zodResolver(newMeetingFormSchema),
    defaultValues: {
      participantIds: targetQueryParam
        ? [userId.toString(), targetQueryParam]
        : [userId.toString()],
      targetId: targetQueryParam ?? undefined,
    },
  });

  const options = participants
    .filter(
      (participant, index, self) =>
        index === self.findIndex((p) => p.id === participant.id),
    )
    .map((participant) => ({
      label: participant.name,
      value: participant.id.toString(),
    }));

  const [currentTarget, setCurrentTarget] = useState<string | undefined>(
    form.getValues("targetId"),
  );

  const targetId = form.watch("targetId");
  const participantIds = form.watch("participantIds");

  useEffect(() => {
    const participantIds = form.getValues("participantIds");

    // If there's a new targetId selected, update participantIds
    if (targetId && targetId !== currentTarget) {
      const updatedParticipantIds = participantIds
        .filter((id) => id !== currentTarget) // Remove the previous targetId
        .concat(targetId); // Add the new targetId

      form.setValue("participantIds", updatedParticipantIds); // Update participantIds
    }
    setCurrentTarget(targetId);
  }, [targetId, form]);

  useEffect(() => {
    if (currentTarget && !participantIds.includes(currentTarget)) {
      const updatedParticipantIds = [...participantIds, currentTarget];
      form.setValue("participantIds", updatedParticipantIds);
    }
    if (!participantIds.includes(userId.toString())) {
      const updatedParticipantIds = [...participantIds, userId.toString()];
      form.setValue("participantIds", updatedParticipantIds);
    }
  }, [participantIds, form, currentTarget]);

  const { mutate: createMeeting } = useMutation({
    mutationFn: (data: {
      meeting_type: string;
      target: number;
      location: string;
      meeting_date: string;
      participants: number[];
      created_by: number;
    }) =>
      fetch("/api/meetings", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          token: userId.toString(),
        },
      }),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["meetings"] });
      navigate("/meetings");
    },
  });

  const onSubmit = (data: NewMeetingFormData) => {
    const payload = {
      // adjust meeting type to the api's format
      meeting_type:
        data.type === MeetingType.oneOnOne
          ? "1:1"
          : data.type === MeetingType.feedback
            ? "feedback"
            : data.type === MeetingType.performance_review
              ? "performance_review"
              : "",
      target: parseInt(data.targetId, 10),
      location: data.location,
      meeting_date: data.date.toISOString(),
      participants: data.participantIds.map((id) => parseInt(id, 10)),
      created_by: userId,
    };

    createMeeting(payload);
  };

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <SelectInput
          control={form.control}
          name="type"
          items={Object.values(MeetingType)}
          valueAccessor={(type) => type}
          labelAccessor={(type) => type}
          label="Meeting type"
          placeholder="Select a type"
        />
        <TextInput
          control={form.control}
          name="location"
          label="Location"
          placeholder="Location"
        />
        <SelectInput
          control={form.control}
          name="targetId"
          label="Meeting target"
          items={targets}
          placeholder="Select a target"
          valueAccessor={(target) => target.id.toString()}
          labelAccessor={(target) => target.name}
        />
        <MultiSelectInput
          key={JSON.stringify(participantIds)}
          control={form.control}
          name="participantIds"
          options={options}
          label="Meeting participants"
          placeholder="Select participants"
          defaultValue={participantIds}
        />
        <DateTimeInput control={form.control} name="date" label="Date" />
        <div className="flex items-center gap-x-2">
          <Button className="text-white" variant="outline" type="submit">
            Save
          </Button>
          <Button
            className="text-white"
            variant="outline"
            onClick={() => {
              navigate("/meetings");
            }}
          >
            Discard
          </Button>
        </div>
      </form>
    </Form>
  );
};
