import { LucideFileText } from "lucide-react";
import { useNavigate } from "react-router-dom";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Note } from "@/types/note";
import { formatMeetingType } from "@/utils/formatMeetingType";
import { NotesProfileCard } from "../cards/NotesProfileCard";

interface NotesCarouselProps {
  notes: Note[];
}

export const NotesCarousel = ({ notes }: NotesCarouselProps) => {
  const navigate = useNavigate();
  return (
    <div className="px-12">
      {notes.length === 0 ? (
        <div className="mt-10 flex h-full items-center justify-center text-slate-400">
          <div className="text-center">
            <LucideFileText
              className="mx-auto mb-4 text-purple-400"
              size={48}
            />

            <p className="mb-1 text-lg font-semibold text-white">
              Looks like there are no notes yet!
            </p>
            <p>No notes available</p>
          </div>
        </div>
      ) : (
        <Carousel
          className="relative mx-auto w-full px-0 pt-5"
          opts={{ loop: false }}
        >
          <CarouselPrevious />
          <CarouselContent className="flex">
            {notes.map((note) => (
              <CarouselItem
                onClick={() => navigate(`/notes/${note.id}`)}
                key={note.id}
                className="h-[350px] w-[300px] max-w-sm flex-shrink-0 flex-grow-0 basis-1/3"
              >
                <NotesProfileCard
                  id={note.id}
                  type={formatMeetingType(note.meeting.meeting_type)}
                  writer={note.writer.name}
                  description={note.description}
                  private_meeting={note.private}
                  created_at={note.created_at}
                  updated_at={note.updated_at}
                  meeting={note.meeting}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselNext />
        </Carousel>
      )}
    </div>
  );
};
