import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Pencil, Plus, X } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Note } from "@/types/note";
import MeetingNoteCard from "../cards/MeetingNoteCard";

interface NoteCarouselProps {
  notes: Note[];
  isPrivate: boolean;
  isEditable: boolean;
  userId: string;
  meetingId: number;
}

const NoteCarousel = ({
  notes,
  isPrivate,
  isEditable,
  userId,
  meetingId,
}: NoteCarouselProps) => {
  /* Create note */
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [noteContent, setNoteContent] = useState("");
  const [errorNewNote, setErrorNewNote] = useState("");

  const queryClient = useQueryClient();

  const { mutate: saveNote } = useMutation({
    mutationFn: (data: {
      meeting_id: number;
      content: string;
      private: boolean;
    }) =>
      fetch("/api/notes", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          token: userId,
          "Content-Type": "application/json",
        },
      }),
    onSuccess: async () => {
      setNoteContent("");
      setIsAddingNote(false);
      await queryClient.invalidateQueries({ queryKey: ["meetings"] });
    },
  });

  const handleSaveNote = (noteContent: string) => {
    if (!noteContent.trim()) {
      setErrorNewNote("Required");
    } else {
      setErrorNewNote("");
      saveNote({
        meeting_id: meetingId,
        content: noteContent,
        private: isPrivate,
      });
    }
  };

  const handleDiscardNote = () => {
    setNoteContent("");
    setErrorNewNote("");
    setIsAddingNote(false);
  };

  /* See note content */
  const [expandedNote, setExpandedNote] = useState<Note | null>(null);
  const handleNoteClick = (note: Note | null) => {
    if (expandedNote === note) {
      setExpandedNote(null);
    } else {
      setExpandedNote(note);
      setIsEditingNote(false);
    }
  };

  /* Edit note */
  const [isEditingNote, setIsEditingNote] = useState(false);
  const [editContent, setEditContent] = useState("");
  const [errorEditNote, setErrorEditNote] = useState("");

  const { mutate: editNote } = useMutation({
    mutationFn: (data: {
      meeting_id: number;
      content: string;
      private: boolean;
      note_id: string;
    }) =>
      fetch(`/api/notes/${data.note_id}`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
          token: userId,
          "Content-Type": "application/json",
        },
      }),
    onSuccess: async () => {
      setIsEditingNote(false);
      handleNoteClick(null);
      await queryClient.invalidateQueries({ queryKey: ["meetings"] });
    },
  });

  const handleEditNoteClick = (note: Note) => {
    setEditContent(note.content);
    setIsEditingNote(true);
  };

  const handleSaveEdit = (editContent: string, note: Note) => {
    if (!editContent.trim()) {
      setErrorEditNote("Required");
    } else {
      setErrorEditNote("");
      editNote({
        meeting_id: meetingId,
        content: editContent,
        private: note.private,
        note_id: note.id.toString(),
      });
    }
  };

  const handleDiscardEdit = () => {
    setIsEditingNote(false);
    setEditContent("");
    setErrorEditNote("");
  };

  const panelRef = useRef(null);
  const [panelWidth, setPanelWidth] = useState(0);

  // Use ResizeObserver to monitor panel width
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        setPanelWidth(entries[0].contentRect.width);
      }
    });

    if (panelRef.current) {
      observer.observe(panelRef.current);
    }

    return () => {
      if (panelRef.current) {
        observer.unobserve(panelRef.current);
      }
    };
  }, []);

  // Decide how many cards to show based on panel width
  const getCardClass = () => {
    if (panelWidth < 600) {
      return "basis-full";
    } else if (panelWidth < 950) {
      return "basis-1/2";
    } else if (panelWidth < 1200) {
      return "basis-1/3";
    } else {
      return "basis-1/4";
    }
  };

  return (
    <div ref={panelRef}>
      <div className="flex items-center justify-between">
        <h1 className="py-2 text-xl font-bold">
          {isPrivate ? "Private Notes" : "Public Notes"}
        </h1>
        {isEditable && (
          <Button
            variant="outline"
            size="icon"
            className="ml-auto text-xl text-white"
            onClick={() => setIsAddingNote(true)}
            aria-label="Create a new meeting"
          >
            <Plus />
          </Button>
        )}
      </div>
      {notes?.length ? (
        <>
          <div className="px-10">
            <Carousel
              className="relative mx-auto w-full px-0 pt-2"
              opts={{ loop: false }}
            >
              <CarouselPrevious />
              <CarouselContent className="flex">
                {notes.map((note) => (
                  <CarouselItem key={note.id} className={getCardClass()}>
                    <div
                      className="hover:cursor-pointer"
                      onClick={() => handleNoteClick(note)}
                    >
                      <MeetingNoteCard
                        id={note.id}
                        writer={note.writer.name}
                        description={note.description}
                        private_note={note.private}
                        created_at={note.created_at}
                        updated_at={note.updated_at}
                      />
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselNext />
            </Carousel>
          </div>

          {/* Display the expanded note content below the carousel, taking full width */}
          {expandedNote != null && (
            <div className="px-10 pt-5">
              <Card className="flex w-full flex-col overflow-hidden border-none bg-gray-800 text-white">
                <CardHeader className="group flex flex-row items-center justify-between bg-black/50 px-4 py-2">
                  <CardTitle className="text-lg text-white">
                    Note Content
                  </CardTitle>
                  <div className="flex items-center gap-2">
                    {isEditable &&
                      expandedNote.writer.id.toString() === userId && (
                        <Button
                          className="flex h-full items-center rounded-md bg-transparent p-2 text-sm font-semibold text-gray-400 hover:bg-gray-800 hover:text-white"
                          onClick={() => handleEditNoteClick(expandedNote)}
                        >
                          <Pencil className="h-4 w-4" />
                        </Button>
                      )}
                    <Button
                      className="flex h-full items-center rounded-md bg-transparent p-2 text-sm font-semibold text-gray-400 hover:bg-gray-800 hover:text-white"
                      onClick={() => handleNoteClick(null)}
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                </CardHeader>
                <CardContent className="flex-1 p-0">
                  {isEditingNote ? (
                    <div>
                      <textarea
                        className="h-full w-full rounded-md bg-slate-300 p-2 px-4 text-sm text-black"
                        value={editContent}
                        onChange={(e) => setEditContent(e.target.value)}
                        placeholder="Write your note here"
                      />
                    </div>
                  ) : (
                    // Correctly return the paragraph here
                    <p className="px-4 py-2 text-sm">{expandedNote.content}</p>
                  )}
                </CardContent>
              </Card>
              {isEditingNote && (
                <div className="flex flex-row justify-between">
                  <div>
                    {errorEditNote && (
                      <p className="mt-1 text-sm text-red-500">
                        {errorEditNote}
                      </p>
                    )}
                  </div>
                  <div className="flex items-center justify-end gap-x-2">
                    <Button
                      onClick={() => handleSaveEdit(editContent, expandedNote)}
                      className="text-white transition duration-300 hover:bg-white"
                      variant="outline"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => handleDiscardEdit()}
                      className="text-white transition duration-300 hover:bg-white"
                      variant="outline"
                    >
                      Discard
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <div>No notes to display</div>
      )}
      {isAddingNote && (
        <div className="mx-10 pt-5">
          <textarea
            className="h-40 w-full rounded-md bg-slate-300 text-sm text-black"
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
            placeholder="Write your note here"
          />
          <div className="flex flex-row justify-between">
            <div>
              {errorNewNote && (
                <p className="mt-1 text-sm text-red-500">{errorNewNote}</p>
              )}
            </div>
            <div className="flex items-center justify-end gap-x-2">
              <Button
                onClick={() => handleSaveNote(noteContent)}
                className="text-white transition duration-300 hover:bg-white"
                variant="outline"
              >
                Save
              </Button>
              <Button
                onClick={() => handleDiscardNote()}
                className="text-white transition duration-300 hover:bg-white"
                variant="outline"
              >
                Discard
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default NoteCarousel;
