import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { MeetingData } from "@/types/meeting_data";
import { User } from "@/types/user";
import { formatMeetingType } from "@/utils/formatMeetingType";
import UpdateMeetingForm from "../forms/UpdateMeetingForm";
import LoadingScreen from "../LoadingScreen";

interface UpdateMeetingDialogProps {
  meeting: MeetingData;
  userId: number;
  userEmail: string;
}

const UpdateMeetingDialog = ({
  meeting,
  userId,
  userEmail,
}: UpdateMeetingDialogProps) => {
  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);

  const {
    data: users,
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
  } = useQuery({
    queryFn: (): Promise<User[]> =>
      fetch("/api/users", {
        method: "GET",
      })
        .then((res) => res.json())
        .then((res) => res.data),
    queryKey: ["users"],
  });

  const {
    data: targets,
    isLoading: isLoadingTargets,
    isError: isErrorTargets,
  } = useQuery({
    queryFn: (): Promise<User[]> =>
      fetch(`/api/users/${userEmail}/employees`)
        .then((res) => res.json())
        .then((res) => res.data),
    queryKey: ["employees"],
  });

  if (isLoadingTargets || isLoadingUsers) {
    return <LoadingScreen />;
  }

  if (isErrorTargets || isErrorUsers || !users || !targets) {
    return <div>Error: error fetching user data</div>;
  }
  return (
    <Dialog
      open={isUpdateDialogOpen}
      onOpenChange={setUpdateDialogOpen}
      modal={true}
    >
      <DialogTrigger asChild>
        <Button
          className="rounded bg-gray-800 px-6 font-semibold text-white transition duration-300 hover:bg-gray-700 focus:ring-0"
          onClick={() => setUpdateDialogOpen(true)}
        >
          Update
        </Button>
      </DialogTrigger>
      <DialogContent className="border-0 bg-primary">
        <DialogHeader>
          <DialogTitle className="text-white">Update a meeting</DialogTitle>
        </DialogHeader>
        <UpdateMeetingForm
          meetingId={meeting.id}
          userId={userId}
          targets={targets}
          participants={users}
          defaultValues={{
            type: formatMeetingType(meeting.meeting_type),
            location: meeting.location,
            targetId: meeting.target.id.toString(),
            participantIds: meeting.participants.map((p) =>
              p.user_id.toString(),
            ),
            date: new Date(meeting.meeting_date),
          }}
          onClose={() => setUpdateDialogOpen(false)}
        />
      </DialogContent>
    </Dialog>
  );
};

export default UpdateMeetingDialog;
