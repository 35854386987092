import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { DateTimeInput } from "@/components/inputs/DateTimeInput";
import { TextInput } from "@/components/inputs/TextInput";
import { Form } from "@/components/ui/form";
import { Button } from "@/ui/common/Button";
import { NewRoundFormData, newRoundFormSchema } from "./schema";

interface NewRoundFormProps {
  defaultValues?: {
    name?: string;
    start_date?: Date;
    end_date?: Date;
  };
  userId: number;
  onClose: () => void;
}

const NewRoundForm = ({ defaultValues, onClose }: NewRoundFormProps) => {
  const queryClient = useQueryClient();

  const form = useForm<NewRoundFormData>({
    resolver: zodResolver(newRoundFormSchema),
    defaultValues,
  });

  const { mutate: newRound } = useMutation({
    mutationFn: (data: {
      name: string;
      start_date: string;
      end_date: string;
    }) =>
      fetch(`/api/rounds/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["rounds"] });
      onClose();
    },
  });

  const onSubmit = (data: NewRoundFormData) => {
    const payload = {
      name: data.name,
      start_date: data.starting_date.toISOString(),
      end_date: data.finishing_date.toISOString(),
    };
    newRound(payload);
  };

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <TextInput
          control={form.control}
          name="name"
          label="Name"
          placeholder="Name"
        />
        <div className="justify flex gap-x-2">
          <DateTimeInput
            control={form.control}
            name="starting_date"
            label="Starting Date"
            removetime={true}
          />
          <DateTimeInput
            control={form.control}
            name="finishing_date"
            label="Finishing Date"
            removetime={true}
          />
        </div>
        <div className="flex justify-end gap-x-2">
          <Button className="text-white" variant="outline" type="submit">
            Create Round
          </Button>
          <Button
            className="text-white"
            variant="outline"
            type="button"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default NewRoundForm;
