import { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { formatDistanceToNow } from "date-fns";
import { ListFilter, Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { MeetingFilterForm } from "@/components/forms/MeetingFilterForm";
import LoadingScreen from "@/components/LoadingScreen";
import { MeetingsTable } from "@/components/tables/MeetingsTable";
import { Button } from "@/components/ui/button";
import { useUserStore } from "@/stores";
import type { Meeting } from "@/types/meeting";
import { User } from "@/types/user";
import Spinner from "@/ui/common/Spinner";
import { ErrorBoundaryFallback } from "../ErrorBoundaryFallback";

export const Meetings = () => {
  const { user } = useUserStore();
  const userId = user!.id;
  const now = useMemo(() => new Date(), []);
  const navigate = useNavigate();

  const [isAddingFilters, setIsAddingFilters] = useState(false);
  const [getMeetingsEndpoint, setGetMeetingsEndpoint] =
    useState("/api/meetings");

  const hasLoadedInitially = useRef(false);

  const {
    data: response,
    isLoading: isLoadingMeeting,
    error: errorMeeting,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["meetings", userId],
    queryFn: (): Promise<{ data: Meeting[] }> =>
      fetch(getMeetingsEndpoint, {
        method: "GET",
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      }).then((response) => response.json()),
    enabled: !!userId,
  });

  const {
    data: users,
    isLoading: isLoadingUsers,
    isError: errorUsers,
    isFetching: isFetchingUsers,
  } = useQuery({
    queryFn: (): Promise<User[]> =>
      fetch("/api/users", {
        method: "GET",
      })
        .then((res) => res.json())
        .then((res) => res.data),
    queryKey: ["users"],
  });

  useEffect(() => {
    if (getMeetingsEndpoint) {
      refetch();
    }
  }, [getMeetingsEndpoint, refetch]);

  useEffect(() => {
    if (!isLoadingMeeting) {
      hasLoadedInitially.current = true;
    }
  }, [isLoadingMeeting]);

  const meetings: Meeting[] = useMemo(() => response?.data ?? [], [response]);

  const formattedMeetings = useMemo(() => {
    return meetings
      .sort(
        (a, b) =>
          new Date(b.meeting_date).getTime() -
          new Date(a.meeting_date).getTime(),
      )
      .map((meeting) => ({
        ...meeting,
        relative_time: formatDistanceToNow(new Date(meeting.meeting_date), {
          addSuffix: true,
        }),
      }));
  }, [meetings, now]);

  if (isLoadingUsers || (!hasLoadedInitially.current && isLoadingMeeting)) {
    return (
      <div data-testid="loading">
        <LoadingScreen />
      </div>
    );
  }

  if (errorMeeting || errorUsers) {
    return <ErrorBoundaryFallback message="Error fetching data" />;
  }

  return (
    <div className="flex h-full flex-col p-10">
      <div className="mb-6 flex justify-between">
        <h1 className="text-2xl font-bold text-primary-white-100">Meetings</h1>
        <div className="flex items-center gap-x-2">
          <Button
            variant="outline"
            size="icon"
            className="ml-auto text-xl text-white"
            onClick={() => setIsAddingFilters(!isAddingFilters)}
            aria-label="Create a new meeting"
          >
            <ListFilter />
          </Button>
          {user!.role === "manager" && (
            <Button
              variant="outline"
              size="icon"
              className="ml-auto text-xl text-white"
              onClick={() => navigate("/meetings/new")}
              aria-label="Create a new meeting"
            >
              <Plus />
            </Button>
          )}
        </div>
      </div>
      <div className="mb-4">
        {!isFetchingUsers && (
          <div className={`collapsible ${isAddingFilters ? "open" : ""}`}>
            <MeetingFilterForm
              targets={users!}
              setGetMeetingsEndpoint={setGetMeetingsEndpoint}
            />
          </div>
        )}
      </div>

      {/* Conditionally render table spinner only for table refetch */}
      {isFetching && hasLoadedInitially.current ? (
        <div className="flex flex-grow items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="pb-5">
          <MeetingsTable meetings={formattedMeetings} />
        </div>
      )}
    </div>
  );
};
