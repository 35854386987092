import { useEffect, useRef, useState } from "react";
import { Plus } from "lucide-react";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Topic } from "@/types/topic";
import TopicCard from "../cards/TopicCard";
import { NewTopicForm } from "../forms/NewTopicForm";
import { Button } from "../ui/button";

interface TopicCarouselProps {
  topics: Topic[];
  isEditable: boolean;
  userId: number;
  meetingId: number;
}

const TopicCarousel = ({
  topics,
  isEditable,
  userId,
  meetingId,
}: TopicCarouselProps) => {
  const panelRef = useRef(null);
  const [panelWidth, setPanelWidth] = useState(0);

  // Use ResizeObserver to monitor panel width
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        setPanelWidth(entries[0].contentRect.width);
      }
    });

    if (panelRef.current) {
      observer.observe(panelRef.current);
    }

    return () => {
      if (panelRef.current) {
        observer.unobserve(panelRef.current);
      }
    };
  }, []);

  // Decide how many cards to show based on panel width
  const getCardClass = () => {
    if (panelWidth < 600) {
      return "basis-full";
    } else if (panelWidth < 950) {
      return "basis-1/2";
    } else if (panelWidth < 1400) {
      return "basis-1/3";
    } else {
      return "basis-1/4";
    }
  };

  const [isAddingTopic, setIsAddingTopic] = useState(false);

  const closeForm = () => {
    setIsAddingTopic(false);
  };

  return (
    <div ref={panelRef}>
      <div className="flex items-center justify-between">
        <h1 className="py-2 text-xl font-bold">Topics</h1>
        {isEditable && (
          <Button
            variant="outline"
            size="icon"
            className="ml-auto text-xl text-white"
            onClick={() => setIsAddingTopic(true)}
            aria-label="Create a new meeting"
          >
            <Plus />
          </Button>
        )}
      </div>
      {topics.length > 0 ? (
        <div className="px-10">
          <Carousel
            className="relative w-full px-0 pt-2"
            opts={{ loop: false }}
          >
            <CarouselPrevious />
            <CarouselContent className="flex">
              {topics.map((topic) => (
                <CarouselItem key={topic.id} className={getCardClass()}>
                  <TopicCard
                    name={topic.name}
                    creator_name={topic.created_by.name}
                    main_topic={topic.main_topic}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselNext />
          </Carousel>
        </div>
      ) : (
        <div>No topics to display</div>
      )}
      {isAddingTopic && (
        <div className="px-10 pt-4 text-black">
          <NewTopicForm
            meetingId={meetingId}
            userId={userId}
            onClose={closeForm}
            defaultValues={{
              main_topic: "false",
            }}
          />
        </div>
      )}
    </div>
  );
};
export default TopicCarousel;
