import { useNavigate } from "react-router-dom";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { getInitials } from "@/utils/getInitials";

interface TargetUserCardProps {
  id: number;
  name: string;
  email: string;
  avatarUrl: string;
  hours: number;
  jobTitle: string;
  lastPosition: string;
}
const TargetUserCard = ({
  id,
  name,
  email,
  avatarUrl,
  hours,
  jobTitle,
  lastPosition,
}: TargetUserCardProps) => {
  const navigate = useNavigate();
  return (
    <div
      className="inline-flex h-28 cursor-pointer flex-row overflow-auto rounded-lg bg-gray-800"
      onClick={() => navigate(`/employees/${id}`)}
    >
      <Avatar className="aspect-square h-auto rounded-none">
        <AvatarImage src={avatarUrl} />
        <AvatarFallback className="rounded-none text-4xl text-black">
          {getInitials(name)}
        </AvatarFallback>
      </Avatar>
      <div className="flex flex-col text-left">
        <div className="flex flex-wrap bg-black/50 p-2 pr-4">
          <h1 className="font-bold">{name} </h1>
          <span className="mx-2 text-gray-400">-</span>
          <h1 className="text-gray-400">{email}</h1>
        </div>
        <div className="flex flex-col justify-center p-2 pr-4">
          <div className="flex flex-wrap">
            <h1 className="text-sm font-bold">Job Title: </h1>
            <span className="mx-1"></span>
            <h1 className="text-sm">{jobTitle + ", " + hours + " hours"}</h1>
          </div>
          <div className="flex flex-wrap">
            <h1 className="text-sm font-bold">Last position: </h1>
            <span className="mx-1"></span>
            <h1 className="text-sm">
              {lastPosition.charAt(0).toUpperCase() + lastPosition.slice(1)}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetUserCard;
