import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";

import EditNoteForm from "@/components/forms/EditNoteForm";
import LoadingScreen from "@/components/LoadingScreen";
import { Button } from "@/components/ui/button";
import { useUserStore } from "@/stores";
import { Meeting } from "@/types/meeting";
import type { Note } from "@/types/note";
import { formatMeetingType } from "@/utils/formatMeetingType";
import { ErrorBoundaryFallback } from "../ErrorBoundaryFallback";

export const NoteDetails = () => {
  const { id } = useParams();
  const { user } = useUserStore();
  const writerId = user!.id;
  const [isEditMode, setIsEditMode] = useState(false);
  const navigation = useNavigate();

  const {
    data: note,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["note", id],
    queryFn: (): Promise<Note> =>
      fetch(`/api/notes/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => res.data),
    enabled: !!id,
  });

  const {
    data: meetings,
    isLoading: isMeetingLoading,
    error: meetingError,
  } = useQuery({
    queryFn: (): Promise<Meeting[]> =>
      fetch(`/api/meetings/`, {
        method: "GET",
        headers: {
          token: user!.id.toString(),
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => res.data),
    queryKey: ["meetings"],
  });

  if (isLoading || isMeetingLoading) {
    return <LoadingScreen />;
  }

  if (error || !note) {
    return <ErrorBoundaryFallback message="Error fetching note data" />;
  }

  if (meetingError || !meetings) {
    return <ErrorBoundaryFallback message="Error fetching meetings" />;
  }

  const toggleEditMode = () => setIsEditMode((prevMode) => !prevMode);

  return (
    <div className="p-10">
      {isEditMode ? (
        <div>
          <h1 className="mb-4 text-2xl font-bold text-white">Edit Note</h1>
          <EditNoteForm
            note={note}
            meeting={note.meeting}
            meetings={meetings}
            writerId={writerId}
            noteId={id!}
            toggleEditMode={toggleEditMode}
          />
        </div>
      ) : (
        <>
          <div className="mb-6 flex items-center justify-between border-b border-gray-700 pb-4">
            {note.meeting === undefined ? (
              <h1 className="text-2xl font-semibold text-gray-400">
                No meeting has been fetched
              </h1>
            ) : (
              <div>
                <h1
                  className="mb-2 cursor-pointer text-3xl font-bold text-gray-300 hover:underline"
                  onClick={() => {
                    navigation(`/meetings/${note.meeting.id}`);
                  }}
                >
                  {formatMeetingType(note.meeting.meeting_type)}
                </h1>
                <h2
                  className="cursor-pointer text-lg font-medium text-gray-300 hover:underline"
                  onClick={() => {
                    navigation(`/employees/${note.meeting.target.id}`);
                  }}
                >
                  {note.meeting.target.name}
                </h2>
                <p className="text-sm text-gray-400">
                  {format(
                    new Date(note.meeting.meeting_date),
                    "EEEE, d MMMM, yyyy",
                  )}
                </p>
              </div>
            )}
            {note.writer.id === user?.id && (
              <Button
                className="ml-auto text-white hover:bg-white"
                variant="outline"
                onClick={toggleEditMode}
              >
                Edit
              </Button>
            )}
          </div>

          <div className="mb-6">
            <h2 className="border-b border-gray-700 pb-2 text-xl font-semibold text-gray-200">
              Description
            </h2>
            <p className="mt-2 text-lg text-gray-300">{note.description}</p>
          </div>

          <div className="mb-6">
            <h2 className="border-b border-gray-700 pb-2 text-xl font-semibold text-gray-200">
              Content
            </h2>
            <p className="mt-2 text-base text-gray-400">{note.content}</p>
          </div>

          <div className="mt-4 flex justify-center">
            <p
              className={`px-4 py-2 text-lg font-bold ${
                note.private ? "text-purple-600" : "text-green-600"
              }`}
            >
              {note.private ? "Private" : "Public"}
            </p>
          </div>
        </>
      )}
    </div>
  );
};
