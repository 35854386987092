import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { DateTimeInput } from "@/components/inputs/DateTimeInput";
import MultiSelectInput from "@/components/inputs/MultiSelectInput";
import RadioGroupInput from "@/components/inputs/RadioGroupInput";
import SelectInput from "@/components/inputs/SelectInput";
import TextAreaInput from "@/components/inputs/TextAreaInput";
import { Form } from "@/components/ui/form";
import { useUserStore } from "@/stores";
import { User } from "@/types/user";
import { Button } from "@/ui";
import { NewRequestFormData, newRequestFormSchema } from "./schema";

interface NewRequestFormProps {
  reviewers: User[];
  targets: User[];
  roundId: string;
  roundStartDate: Date;
  roundEndDate: Date;
}

export const NewRequestForm = ({
  reviewers,
  targets,
  roundId,
  roundStartDate,
  roundEndDate,
}: NewRequestFormProps) => {
  const form = useForm<NewRequestFormData>({
    resolver: zodResolver(newRequestFormSchema(roundStartDate, roundEndDate)),
    defaultValues: {
      mandatory: "true",
    },
  });

  const { user } = useUserStore();

  const navigate = useNavigate();

  const { isPending, mutate: createRequest } = useMutation({
    mutationFn: (data: {
      round_id: number;
      target_id: number;
      due_date: string;
      question: string;
      mandatory: boolean;
      reviewers: number[];
    }) =>
      fetch("/api/rounds/requests", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          token: user!.id.toString(),
          "Content-Type": "application/json",
        },
      }),

    onSuccess: async () => {
      await navigate(-1);
    },

    onError: (error) => {
      console.error("Error creating request", error);
    },
  });

  const onSubmit = (data: NewRequestFormData) => {
    createRequest({
      round_id: parseInt(roundId),
      target_id: parseInt(data.targetId),
      due_date: data.deadline.toISOString(),
      question: data.question,
      mandatory: data.mandatory === "true",
      reviewers: data.reviewerIds.map((id) => parseInt(id)),
    });
  };

  const reviewersOptions = reviewers
    .filter(
      (reviewer, index, self) =>
        index === self.findIndex((p) => p.id === reviewer.id),
    )
    .map((reviewer) => ({
      label: reviewer.name,
      value: reviewer.id.toString(),
    }));

  const uniqueTargets = targets.filter(
    (user, index, self) => index === self.findIndex((u) => u.id === user.id),
  );

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <MultiSelectInput
          control={form.control}
          name="reviewerIds"
          options={reviewersOptions}
          label="Reviewers"
          placeholder="Select reviewers"
        />

        <SelectInput
          control={form.control}
          name="targetId"
          items={uniqueTargets}
          label="Target"
          valueAccessor={(target) => target.id.toString()}
          labelAccessor={(target) => target.name}
          placeholder="Select a target"
        />

        <DateTimeInput
          control={form.control}
          name="deadline"
          label="Deadlline"
          removetime={true}
        />

        <TextAreaInput
          control={form.control}
          name="question"
          label="What do we want to know?"
        />

        <div className="mt-2 flex items-center justify-between">
          <RadioGroupInput
            control={form.control}
            name="mandatory"
            items={[
              { value: "true", label: "Mandatory" },
              { value: "false", label: "Optional" },
            ]}
            valueAccessor={(item) => item.value}
            labelAccessor={(item) => item.label}
          />
          <div className="flex items-center gap-x-2">
            <Button
              className="text-white"
              variant="outline"
              type="submit"
              disabled={isPending}
            >
              Save
            </Button>
            <Button
              className="text-white"
              variant="outline"
              onClick={() => {
                navigate(-1);
              }}
            >
              Discard
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};
