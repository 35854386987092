import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useUserStore } from "@/stores";
import type { Meeting } from "@/types/meeting";
import { MeetingState } from "@/types/meeting";
import { formatMeetingType } from "@/utils/formatMeetingType";
import { formatParticipantList } from "@/utils/formatParticipantList";

interface MeetingsTableProps {
  meetings: Meeting[];
}

export const MeetingsTable = ({ meetings }: MeetingsTableProps) => {
  const { user } = useUserStore();
  const navigate = useNavigate();

  return (
    <>
      {meetings.length === 0 ? (
        <div className="flex h-full items-center justify-center text-slate-400">
          <p>No meetings</p>
        </div>
      ) : (
        <div className="h-full text-white">
          <Table className="rounded-t-lg bg-gray-800">
            <TableHeader>
              <TableRow className="border-none bg-black/50 hover:bg-black/50">
                <TableHead className="rounded-tl-lg font-bold text-white">
                  Meeting Type
                </TableHead>
                <TableHead className="font-bold text-white">
                  Participants
                </TableHead>
                <TableHead className="font-bold text-white">Location</TableHead>
                <TableHead className="rounded-tr-lg font-bold text-white">
                  Date
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {meetings.map((meeting) => (
                <TableRow
                  data-testid={`meeting-row${meeting.id}`}
                  onClick={() => navigate(`/meetings/${meeting.id}`)}
                  key={meeting.id}
                  className="cursor-pointer hover:bg-slate-700"
                >
                  <TableCell>
                    {formatMeetingType(meeting.meeting_type)}
                  </TableCell>
                  <TableCell>
                    {!meeting.participants ? (
                      <p> No participants </p>
                    ) : (
                      formatParticipantList(
                        meeting.participants.map((p) => p.name),
                        user!.name,
                      )
                    )}
                  </TableCell>
                  <TableCell>
                    {meeting.location
                      ? meeting.location
                      : "No meeting location"}
                  </TableCell>
                  {meeting.state == MeetingState.cancelled ? (
                    <TableCell style={{ color: "red" }}>
                      {format(
                        new Date(meeting.meeting_date),
                        "eeee, d MMMM, yyyy",
                      )}
                      <br />
                      <small>
                        {format(new Date(meeting.meeting_date), "h:mm a")}
                      </small>
                      <br />
                      <small>({meeting.relative_time}) CANCELLED</small>
                    </TableCell>
                  ) : meeting.state === MeetingState.done ? (
                    <TableCell style={{ color: "lightgreen" }}>
                      {format(
                        new Date(meeting.meeting_date),
                        "eeee, d MMMM, yyyy",
                      )}
                      <br />
                      <small>
                        {format(new Date(meeting.meeting_date), "p")}
                      </small>
                      <br />
                      <small>({meeting.relative_time}) DONE</small>
                    </TableCell>
                  ) : (
                    <TableCell>
                      {format(
                        new Date(meeting.meeting_date),
                        "EEEE, d MMMM, yyyy",
                      )}
                      <br />
                      <small>
                        {format(new Date(meeting.meeting_date), "p")}
                      </small>
                      <br />
                      <small>({meeting.relative_time})</small>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="flex h-10 rounded-b-lg bg-black/50"></div>
        </div>
      )}
    </>
  );
};
