import { z } from "zod";

export const meetingFilterFormSchema = z.object({
  target: z.string().optional(),
  type: z.string().optional(),
  creator: z.string().optional(),
  participant: z.string().optional(),
  from: z.date().optional(),
  to: z.date().optional(),
});

export type MeetingFilterFormData = z.infer<typeof meetingFilterFormSchema>;
