import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import TextAreaInput from "@/components/inputs/TextAreaInput";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { CancelMeetingFormData, cancelMeetingFormSchema } from "./schema";

interface CancelMeetingFormProps {
  defaultValues?: {
    motive?: string;
  };
  // Id for the meeting to cancel
  meetingId: number;
  // Id of the user who is canceling the meeting
  userId: number;
  onClose: () => void;
}

const CancelMeetingForm = ({
  userId,
  meetingId,
  defaultValues,
  onClose,
}: CancelMeetingFormProps) => {
  const queryClient = useQueryClient();

  const form = useForm<CancelMeetingFormData>({
    resolver: zodResolver(cancelMeetingFormSchema),
    defaultValues,
  });

  const { isPending, mutate: cancelMeeting } = useMutation({
    mutationFn: (data: { motive: string }) =>
      fetch(`/api/meetings/${meetingId}/cancel`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      }),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["meetings"] });
      onClose();
    },
  });

  const onSubmit = (data: CancelMeetingFormData) => {
    cancelMeeting({
      motive: data.motive,
    });
  };

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <TextAreaInput control={form.control} name="motive" label="Motive" />
        <div className="flex justify-end gap-x-2">
          <Button
            className="text-white"
            variant="outline"
            type="submit"
            disabled={isPending}
          >
            Yes
          </Button>
          <Button
            className="text-white"
            variant="outline"
            type="button"
            onClick={() => onClose()}
          >
            No
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default CancelMeetingForm;
