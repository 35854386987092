import { z } from "zod";

export const newRequestFormSchema = (
  roundStartDate: Date,
  roundEndDate: Date,
) =>
  z
    .object({
      targetId: z.string().regex(/^\d+$/),
      reviewerIds: z.array(z.string().regex(/^\d+$/)),
      deadline: z.date().refine((date) => date >= new Date(), {
        message: "The request cannot be in the past.",
      }),
      question: z.string(),
      mandatory: z.union([z.literal("true"), z.literal("false")]),
    })
    .refine(
      (data) =>
        data.deadline >= roundStartDate && data.deadline <= roundEndDate,
      {
        path: ["deadline"],
        message: `Choose a deadline within the round's date range, between ${roundStartDate.toLocaleDateString()} and ${roundEndDate.toLocaleDateString()}.`,
      },
    );

export type NewRequestFormData = z.infer<
  ReturnType<typeof newRequestFormSchema>
>;
