import { z } from "zod";

export const editNoteFormSchema = z.object({
  meeting_id: z.string(),
  description: z.string(),
  content: z.string(),
  private: z.union([z.literal("true"), z.literal("false")]),
});

export type EditNoteFormData = z.infer<typeof editNoteFormSchema>;
