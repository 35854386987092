import { useQuery } from "@tanstack/react-query";

import { NewMeetingForm } from "@/components/forms/NewMeetingForm";
import LoadingScreen from "@/components/LoadingScreen";
import { useUserStore } from "@/stores";
import { User } from "@/types/user";
import { ErrorBoundaryFallback } from "../ErrorBoundaryFallback";

export const CreateMeetingScreen = () => {
  const { user } = useUserStore();

  const {
    data: users,
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
  } = useQuery({
    queryFn: (): Promise<User[]> =>
      fetch("/api/users", {
        method: "GET",
      })
        .then((res) => res.json())
        .then((res) => res.data),
    queryKey: ["users"],
  });

  const email = user!.email;

  const {
    data: employees,
    isLoading: isLoadingEmployees,
    isError: isErrorEmployees,
  } = useQuery({
    queryFn: (): Promise<User[]> =>
      fetch(`/api/users/${email}/employees`)
        .then((res) => res.json())
        .then((res) => res.data),
    queryKey: ["employees"],
  });

  if (isLoadingEmployees || isLoadingUsers) {
    return <LoadingScreen />;
  }

  if (isErrorEmployees || isErrorUsers) {
    return <ErrorBoundaryFallback message="Error fetching" />;
  }

  if (!users?.length || !employees?.length) {
    return <ErrorBoundaryFallback message="No employees found" />;
  }

  return (
    <div className="space-y-4 p-10">
      <h1 className="text-2xl font-bold text-primary-white-100">
        Create Meeting
      </h1>
      <NewMeetingForm targets={employees} participants={users} />
    </div>
  );
};
