import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import LoadingScreen from "@/components/LoadingScreen";
import { PendingFeedbacksTable } from "@/components/tables/PendingFeedbacksTable";
import { ReceivedFeedbacksTable } from "@/components/tables/ReceivedFeedbacksTable";
import { ErrorBoundaryFallback } from "@/screens/ErrorBoundaryFallback";
import { useUserStore } from "@/stores";
import { RFeedbacks } from "@/types/round";
import { separateFeedbacks } from "@/utils/separateFeedbacks";

export const ReceivedFeedbacks = () => {
  const { user } = useUserStore();
  const { id } = useParams();
  const userId = user!.id;

  const {
    data: received_feedbacks,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["receivedFeedbacks", id],
    queryFn: (): Promise<RFeedbacks> => {
      return fetch(`/api/rounds/${id}/received-feedbacks`, {
        method: "GET",
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => response.data);
    },
    enabled: !!id,
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) return <ErrorBoundaryFallback message={error.message} />;

  if (!received_feedbacks)
    return <ErrorBoundaryFallback message="No feedbacks found" />;

  const allFeedbacks = received_feedbacks!.requests.flatMap((requestGroup) =>
    requestGroup.flatMap((request) =>
      request.feedbacks.map((feedback) => ({
        ...feedback,
        due_date: request.due_date,
        requestId: request.id,
      })),
    ),
  );

  const { pendingFeedbacks, receivedFeedbacks } =
    separateFeedbacks(allFeedbacks);

  return (
    <div className="p-10">
      <div className="mb-6 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-primary-white-100">
          Received Feedbacks
        </h1>
        <h1 className="text-2xl font-bold text-primary-white-100">
          Round: {received_feedbacks.round.name}
        </h1>
      </div>

      <h2 className="mb-2 text-xl font-semibold text-primary-white-100">
        Received
      </h2>
      <ReceivedFeedbacksTable feedbacks={receivedFeedbacks} />
      <h2 className="mb-2 mt-8 text-xl font-semibold text-primary-white-100">
        Pending
      </h2>
      <PendingFeedbacksTable feedbacks={pendingFeedbacks} />
    </div>
  );
};
