import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { Form } from "@/components/ui/form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { MeetingFeeling } from "@/types/meeting";
import { SetFeelingFormData, setFeelingFormSchema } from "./schema";

interface SetFeelingFormProps {
  defaultValues?: {
    how_i_feel?: MeetingFeeling;
  };
  meetingId: number;
  userId: number;
  disabled: boolean;
}

const SetFeelingForm = ({
  meetingId,
  userId,
  disabled,
  defaultValues,
}: SetFeelingFormProps) => {
  const form = useForm<SetFeelingFormData>({
    resolver: zodResolver(setFeelingFormSchema),
    defaultValues,
  });

  const queryClient = useQueryClient();

  const { mutate: SetFeeling } = useMutation({
    mutationFn: (data: { feeling: MeetingFeeling }) =>
      fetch(`/api/meetings/${meetingId}/feeling`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      }),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["meetings"] });
    },
  });

  const onSubmit = (data: SetFeelingFormData) => {
    SetFeeling({ feeling: data.how_i_feel });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex justify-center">
          <RadioGroup
            className="flex space-x-4"
            value={form.getValues("how_i_feel")}
            disabled={disabled}
            onValueChange={(value: MeetingFeeling) => {
              if (!disabled) {
                form.setValue("how_i_feel", value);
                form.handleSubmit(onSubmit)();
              }
            }}
          >
            <div className="flex flex-col items-center">
              <RadioGroupItem
                value={MeetingFeeling.bad}
                className="h-6 w-6 bg-red-500"
                style={{
                  opacity: disabled ? 1 : undefined,
                  pointerEvents: disabled ? "none" : undefined,
                }}
              />
              <label className="text- text-white">Bad</label>
            </div>
            <div className="flex flex-col items-center">
              <RadioGroupItem
                value={MeetingFeeling.neutral}
                className="h-6 w-6 bg-yellow-500"
                style={{
                  opacity: disabled ? 1 : undefined,
                  pointerEvents: disabled ? "none" : undefined,
                }}
              />
              <label className="text-md text-white">Neutral</label>
            </div>
            <div className="flex flex-col items-center">
              <RadioGroupItem
                value={MeetingFeeling.good}
                className="h-6 w-6 bg-green-500"
                style={{
                  opacity: disabled ? 1 : undefined,
                  pointerEvents: disabled ? "none" : undefined,
                }}
              />
              <label className="text-md text-white">Good</label>
            </div>
          </RadioGroup>
        </div>
      </form>
    </Form>
  );
};

export default SetFeelingForm;
