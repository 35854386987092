import { useQuery } from "@tanstack/react-query";
import { Plus } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

import LoadingScreen from "@/components/LoadingScreen";
import { RequestsSummariesTable } from "@/components/tables/RequestsSummariesTable";
import { Button } from "@/components/ui/button";
import { ErrorBoundaryFallback } from "@/screens/ErrorBoundaryFallback";
import { useUserStore } from "@/stores";
import type { RequestSummary } from "@/types/request";
import type { Round } from "@/types/round";

interface SelectedRound extends Round {
  round: Round;
  requests: RequestSummary[];
}

export const Requests = () => {
  const { user } = useUserStore();
  const userId = user!.id;
  const { id: roundId } = useParams();
  const navigate = useNavigate();

  const {
    data: roundData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["requests", userId],
    queryFn: (): Promise<SelectedRound> =>
      fetch(`/api/rounds/${roundId}/requests`, {
        method: "GET",
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => response.data.round),
    enabled: !!userId,
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorBoundaryFallback message={error.message} />;
  }

  return (
    <div className="p-10">
      <div className="mb-6 flex justify-between">
        <h1 className="text-2xl font-bold text-primary-white-100">
          {roundData!.name} requests
        </h1>
        <Button
          variant="outline"
          size="icon"
          className="ml-auto text-lg text-white"
          onClick={() =>
            navigate(`new`, {
              state: {
                roundStartDate: roundData!.start_date,
                roundEndDate: roundData!.end_date,
              },
            })
          }
          aria-label="Create a new request"
        >
          <Plus />
        </Button>
      </div>
      <RequestsSummariesTable requests={roundData!.requests} />
    </div>
  );
};
