import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { ChevronUp, Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Collapsible, CollapsibleContent } from "@/components/ui/collapsible";
import { ActionItem } from "@/types/action_item";
import { Participant } from "@/types/user";
import { getInitials } from "@/utils/getInitials";
import { NewActionItemForm } from "../forms/NewActionItemForm";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

interface ActionItemCollapsibleProps {
  actionItems: ActionItem[];
  isEditable: boolean;
  userId: number;
  meetingId: number;
  targets: Participant[];
}

const ActionItemCollapsible = ({
  actionItems,
  isEditable,
  userId,
  meetingId,
  targets,
}: ActionItemCollapsibleProps) => {
  const [isAddingActionItem, setIsAddingActionItem] = useState(false);
  const [isRowExpanded, setIsRowExpanded] = useState<boolean[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setIsRowExpanded(new Array(actionItems.length).fill(false));
  }, [actionItems]);

  const toggleExpand = (index: number) => {
    setIsRowExpanded((prev) =>
      prev.map((expanded, i) => (i === index ? !expanded : expanded)),
    );
  };

  const closeForm = () => {
    setIsAddingActionItem(false);
  };

  const queryClient = useQueryClient();

  const { mutate: updateActionItem, isPending } = useMutation({
    mutationFn: (data: ActionItem) =>
      fetch(`/api/meetings/${meetingId}/action-item/${data.id}`, {
        method: "PATCH",
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          completed: !data.completed,
        }),
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["meetings"] });
    },
  });

  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="pt-2 text-xl font-bold">Action Items</h1>
        {isEditable && (
          <Button
            variant="outline"
            size="icon"
            className="ml-auto text-xl text-white"
            onClick={() => setIsAddingActionItem(true)}
            aria-label="Create a new meeting"
          >
            <Plus />
          </Button>
        )}
      </div>
      {!actionItems.length ? (
        <p>No action items available</p>
      ) : (
        <div className="mt-4 overflow-hidden rounded-lg">
          {actionItems.map((item, row) => (
            <Collapsible open={isRowExpanded[row]} key={item.id}>
              <div className="flex items-center justify-between bg-black/30 px-3 py-3">
                <div
                  className="flex items-center gap-x-2 hover:cursor-pointer"
                  onClick={() => {
                    toggleExpand(row);
                  }}
                >
                  <div
                    className={`duration-400 transition-transform ${
                      isRowExpanded[row] ? "" : "rotate-180"
                    }`}
                  >
                    <ChevronUp className="h-5 w-5" />
                  </div>
                  <p className="text-lg">{item.title}</p>
                </div>

                <div className="flex items-center gap-x-4">
                  <p>{format(new Date(item.due_date), "EEEE, d MMMM, yyyy")}</p>
                  <Checkbox
                    className="size-6"
                    checked={item.completed}
                    onClick={() => {
                      updateActionItem(item);
                    }}
                    disabled={isPending || !isEditable}
                    style={{
                      opacity: !isEditable ? 1 : undefined,
                      pointerEvents: !isEditable ? "none" : undefined,
                    }}
                  />
                </div>
              </div>
              <CollapsibleContent>
                <div className="space-y-2 bg-gray-800 px-4 py-2">
                  <div>
                    <p className="font-bold">Description:</p>
                    <p>{item.description}</p>
                  </div>

                  <div className="flex flex-wrap items-center gap-x-4">
                    <div className="flex items-center gap-x-2">
                      <p className="font-bold">Created By:</p>
                      <div className="flex items-center gap-x-2">
                        <Avatar
                          className="h-8 w-8"
                          data-testid={`avatar-image-${item.creator.id}`}
                        >
                          <AvatarImage src={item.creator.avatarUrl} />
                          <AvatarFallback className="bg-gray-600 font-bold text-white">
                            {getInitials(item.creator.name)}
                          </AvatarFallback>
                        </Avatar>
                        <p>
                          <div
                            className="hover:cursor-pointer"
                            onClick={() =>
                              navigate(`/employees/${item.creator.id}`)
                            }
                          >
                            {item.creator.name}
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <p className="font-bold">Assigned To:</p>
                      <div className="flex items-center gap-x-2">
                        <Avatar
                          className="h-8 w-8"
                          data-testid={`avatar-image-${item.assigned.id}`}
                        >
                          <AvatarImage src={item.assigned.avatarUrl} />
                          <AvatarFallback className="bg-gray-600 font-bold text-white">
                            {getInitials(item.assigned.name)}
                          </AvatarFallback>
                        </Avatar>
                        <p>
                          <div
                            className="hover:cursor-pointer"
                            onClick={() =>
                              navigate(`/employees/${item.assigned.id}`)
                            }
                          >
                            {item.assigned.name}
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </CollapsibleContent>
            </Collapsible>
          ))}
        </div>
      )}
      {isAddingActionItem && (
        <div className="text-black">
          <NewActionItemForm
            meetingId={meetingId}
            userId={userId}
            targets={targets}
            onClose={closeForm}
          />
        </div>
      )}
    </div>
  );
};
export default ActionItemCollapsible;
