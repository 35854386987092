// components/forms/EditNoteForm/index.tsx
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { useForm } from "react-hook-form";

import RadioGroupInput from "@/components/inputs/RadioGroupInput";
import SelectInput from "@/components/inputs/SelectInput";
import TextAreaInput from "@/components/inputs/TextAreaInput";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import type { Meeting } from "@/types/meeting";
import { Note } from "@/types/note";
import { formatMeetingType } from "@/utils/formatMeetingType";
import { EditNoteFormData, editNoteFormSchema } from "./schema";

interface EditNoteFormProps {
  note: Note;
  meeting: Meeting;
  meetings: Meeting[];
  noteId: string;
  writerId: number;
  toggleEditMode: () => void;
}

const EditNoteForm = ({
  note,
  meetings,
  noteId,
  writerId,
  toggleEditMode,
}: EditNoteFormProps) => {
  const queryClient = useQueryClient();

  const form = useForm<EditNoteFormData>({
    resolver: zodResolver(editNoteFormSchema),
    defaultValues: {
      meeting_id: note.meeting.id.toString(),
      description: note.description,
      content: note.content,
      private: note.private ? "true" : "false",
    },
  });

  const { isPending, mutate: editNote } = useMutation({
    mutationFn: (data: {
      meeting_id: number;
      content: string;
      description?: string;
      private: boolean;
    }) =>
      fetch(`/api/notes/${noteId}`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
          token: writerId.toString(),
          "Content-Type": "application/json",
        },
      }),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["note", noteId] });
      toggleEditMode();
    },
  });

  const onSubmit = (data: EditNoteFormData) => {
    const payload: {
      meeting_id: number;
      content: string;
      description?: string;
      private: boolean;
    } = {
      meeting_id: parseInt(data.meeting_id),
      content: data.content,
      private: data.private == "true",
    };

    if (data.description !== note.description) {
      payload.description = data.description;
    }

    editNote(payload);
  };

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <SelectInput
          className="text-black"
          control={form.control}
          name="meeting_id"
          items={meetings}
          label="Meeting"
          valueAccessor={(meeting) => meeting.id.toString()}
          labelAccessor={(meeting) =>
            `${formatMeetingType(meeting.meeting_type)} - ${meeting.target.name} - ${format(new Date(meeting.meeting_date), "dd/MM/yyyy")}`
          }
          placeholder="Select a meeting"
        />
        <TextAreaInput
          control={form.control}
          name="description"
          label="Description"
          className="h-auto"
        />
        <TextAreaInput control={form.control} name="content" label="Content" />
        <div className="mt-2 flex items-center justify-between">
          <RadioGroupInput
            control={form.control}
            name="private"
            items={[
              { value: "true", label: "Private" },
              { value: "false", label: "Public" },
            ]}
            valueAccessor={(item) => item.value}
            labelAccessor={(item) => item.label}
          />

          <div className="flex items-center gap-x-2">
            <Button
              className="text-white"
              variant="outline"
              type="submit"
              disabled={isPending}
            >
              Save
            </Button>
            <Button
              className="text-white"
              variant="outline"
              onClick={toggleEditMode}
            >
              Discard
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default EditNoteForm;
