import { Calendar } from "@/components/ui/calendar";

interface DashboardCalendarProps {
  actionItemDates: Date[];
  meetingDates: Date[];
  matchingDates: Date[];
}

const DashboardCalendar = ({
  actionItemDates,
  meetingDates,
  matchingDates,
}: DashboardCalendarProps) => {
  const modifiers = {
    actionItems: actionItemDates,
    meetings: meetingDates,
    matching: matchingDates,
  };

  return (
    <Calendar
      className="rounded-md bg-gray-800 text-white"
      mode="single"
      selected={undefined}
      onSelect={() => {}}
      modifiers={modifiers}
      modifiersClassNames={{
        matching:
          "bg-gradient-to-r from-yellow-400 to-purple-400 text-black rounded-md",
        meetings: "bg-purple-400 text-black rounded-md",
        actionItems: "bg-yellow-400 text-black rounded-md",
      }}
    />
  );
};

export default DashboardCalendar;
