import { useQuery } from "@tanstack/react-query";
import { Plus } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

import { MeetingsCarousel } from "@/components/carousels/MeetingsCarousel";
import { NotesCarousel } from "@/components/carousels/NotesCarousel";
import ToDoActionItemCollapsible from "@/components/collapsibles/ToDoActionItemsCollapsible";
import LoadingScreen from "@/components/LoadingScreen";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { useUserStore } from "@/stores";
import type { ActionItem } from "@/types/action_item";
import type { Meeting } from "@/types/meeting";
import { Note } from "@/types/note";
import type { User } from "@/types/user";
import { getInitials } from "@/utils/getInitials";
import { ErrorBoundaryFallback } from "../ErrorBoundaryFallback";

interface UserData extends User {
  notes: Note[];
  meetings: Meeting[];
  actions: ActionItem[];
}

export const EmployeeDetails = () => {
  const { user } = useUserStore();
  const userId = user!.id;
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: response,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["userNotes", id],
    queryFn: (): Promise<{ data: UserData }> =>
      fetch(`/api/users/${id}`, {
        method: "GET",
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      }).then((response) => response.json()),
    enabled: !!id,
  });

  const employee = response?.data;

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error || !employee) {
    return <ErrorBoundaryFallback message="Error fetching employee data" />;
  }

  const ownProfile = userId.toString() == id;

  return (
    <div className="p-10 text-white">
      <div className="mb-4 flex items-center gap-4">
        <Avatar
          className="h-[125px] w-[125px]"
          data-testid={`avatar-image-${employee.id}`}
        >
          <AvatarImage src={employee.avatarUrl} />
          <AvatarFallback className="bg-gray-600 text-4xl font-bold text-white">
            {getInitials(employee.name)}
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-col">
          <h1 className="mb-0 text-2xl font-bold text-white">
            {employee.name ?? "Name"}
          </h1>
          <p className="not-prose text-white">{employee.email ?? "Mail"}</p>
          {employee.lastPosition ? (
            <p className="not-prose text-white">
              {employee.lastPosition.charAt(0).toUpperCase() +
                employee.lastPosition.slice(1)}
            </p>
          ) : (
            <p className="not-prose text-white">Description</p>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between py-5">
        <h2 className="relative text-2xl font-bold text-white">Meetings</h2>
        {userId != Number(id) && user!.role === "manager" ? (
          <Button
            variant="outline"
            size="icon"
            className="ml-auto text-xl text-white"
            onClick={() => navigate("/meetings/new?target=" + id)}
          >
            <Plus />
          </Button>
        ) : null}
      </div>
      <MeetingsCarousel meetings={employee.meetings} ownProfile={ownProfile} />
      <div className="mt-5">
        <h2 className="relative text-2xl font-bold text-white">Notes</h2>
      </div>
      <NotesCarousel notes={employee.notes} />
      <div className="mt-5">
        <h2 className="relative text-2xl font-bold text-white">Action Items</h2>
      </div>
      <div className="py-5">
        <ToDoActionItemCollapsible
          actionItems={employee.actions}
          userId={user!.id}
          ownProfile={ownProfile}
          refetch={refetch}
        />
      </div>
    </div>
  );
};
