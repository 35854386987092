import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import type { RequestWithFeedbacks } from "@/types/request";
import { getIconByFeeling } from "@/utils/getIconByFeeling";
import { getInitials } from "@/utils/getInitials";

interface SentFeedbacksTableProps {
  requests: RequestWithFeedbacks[];
}

export const SentFeedbacksTable = ({ requests }: SentFeedbacksTableProps) => {
  const navigate = useNavigate();

  return (
    <>
      {requests.length === 0 ? (
        <div className="flex h-full items-center justify-center text-slate-400">
          <p>No feedbacks to show.</p>
        </div>
      ) : (
        <div className="rounded-md bg-gray-800 text-white">
          <Table className="rounded-t-lg bg-gray-800">
            <TableHeader>
              <TableRow className="border-none bg-black/50 hover:bg-black/50">
                <TableHead className="w-1/3 rounded-tl-lg font-bold text-white">
                  Target
                </TableHead>
                <TableHead className="w-1/3 text-center font-bold text-white">
                  Submitted
                </TableHead>
                <TableHead className="w-1/3 rounded-tr-lg text-center font-bold text-white">
                  Feeling
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="text-lg">
              {requests.map((request) =>
                request.feedbacks.map((feedback) => (
                  <TableRow
                    data-testid={`request-row${request.id}-${feedback.id}`}
                    key={`${request.id}-${feedback.id}`}
                    className="cursor-pointer hover:bg-slate-700"
                    onClick={() => {
                      navigate(
                        `/requests/${request.id}/feedbacks/${feedback.id}`,
                      );
                    }}
                  >
                    {/* Target cell */}
                    <TableCell className="flex items-center space-x-3">
                      <Avatar
                        className="h-8 w-8"
                        data-testid={`avatar-image-${request.target.id}`}
                      >
                        <AvatarImage src={request.target.avatarUrl} />
                        <AvatarFallback className="bg-gray-600 font-bold text-white">
                          {getInitials(request.target.name)}
                        </AvatarFallback>
                      </Avatar>
                      <small>{request.target.name}</small>
                    </TableCell>

                    {/* Submitted date cell */}
                    <TableCell className="text-center">
                      <small>
                        {feedback.submitted_at
                          ? format(
                              new Date(feedback.submitted_at),
                              "MMMM d, yyyy",
                            )
                          : "Not submitted"}
                      </small>
                    </TableCell>

                    {/* Feeling cell */}
                    <TableCell>
                      <div className="flex justify-center">
                        {getIconByFeeling(feedback.feeling)}
                      </div>
                    </TableCell>
                  </TableRow>
                )),
              )}
            </TableBody>
          </Table>
          <div className="flex h-10 rounded-b-lg bg-black/50"></div>
        </div>
      )}
    </>
  );
};
