import { z } from "zod";

import { MeetingType } from "@/types/meeting";

export const updateMeetingFormSchema = z.object({
  type: z.nativeEnum(MeetingType),
  targetId: z.string().regex(/^\d+$/).min(1, "Required"),
  location: z.string(),
  date: z.date().refine((date) => date >= new Date(), {
    message: "The meeting date cannot be in the past.",
  }),
  participantIds: z.array(z.string().regex(/^\d+$/)).min(1, "Required"),
  motive: z.string(),
});

export type UpdateMeetingFormData = z.infer<typeof updateMeetingFormSchema>;
