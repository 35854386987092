import { ComponentProps } from "react";
import { Path, type Control, type FieldValues } from "react-hook-form";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

export type TextInputProps<TFieldValues extends FieldValues, TContext> = {
  control: Control<TFieldValues, TContext>;
  name: Path<TFieldValues>;
  label?: string;
  required?: boolean;
  placeholder?: string;
  type?: ComponentProps<typeof Input>["type"];
  className?: string;
};

export function TextInput<TFieldValues extends FieldValues, TContext>({
  control,
  name,
  label,
  required = false,
  placeholder,
  type = "text",
  className,
}: TextInputProps<TFieldValues, TContext>) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          {label && <FormLabel className="text-white">{label}</FormLabel>}
          <FormControl>
            <Input
              className="bg-slate-300"
              placeholder={placeholder}
              type={type}
              required={required}
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
