import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import RadioGroupInput from "@/components/inputs/RadioGroupInput";
import { TextInput } from "@/components/inputs/TextInput";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { NewTopicFormData, newTopicFormSchema } from "./schema";

interface NewTopicFormProps {
  defaultValues?: {
    main_topic?: "true" | "false";
  };
  meetingId: number;
  userId: number;
  onClose: () => void;
}

export const NewTopicForm = ({
  defaultValues,
  meetingId,
  userId,
  onClose,
}: NewTopicFormProps) => {
  const form = useForm<NewTopicFormData>({
    resolver: zodResolver(newTopicFormSchema),
    defaultValues,
  });

  const queryClient = useQueryClient();

  const { mutate: createTopic } = useMutation({
    mutationFn: (data: {
      meeting_id: number;
      name: string;
      main_topic: boolean;
    }) =>
      fetch(`/api/meetings/${meetingId}/topics`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          token: userId.toString(),
        },
      }),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["meetings"] });
      onClose();
    },
  });

  const onSubmit = (data: NewTopicFormData) => {
    const payload = {
      meeting_id: meetingId,
      name: data.title,
      main_topic: data.main_topic === "true",
    };
    console.log(payload);
    createTopic(payload);
  };

  return (
    <Form {...form}>
      <form className="space-y-2" onSubmit={form.handleSubmit(onSubmit)}>
        <TextInput
          control={form.control}
          name="title"
          label="New Topic"
          placeholder="New Topic"
        />
        <div className="mt-2 flex items-center justify-between">
          <RadioGroupInput
            control={form.control}
            name="main_topic"
            items={[
              { value: "true", label: "Main Topic" },
              { value: "false", label: "Sub Topic" },
            ]}
            valueAccessor={(item) => item.value}
            labelAccessor={(item) => item.label}
          />
          <div className="flex items-center justify-end gap-x-2">
            <Button className="text-white" variant="outline" type="submit">
              Save
            </Button>
            <Button className="text-white" variant="outline" onClick={onClose}>
              Discard
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};
