import { useState } from "react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import CancelMeetingForm from "../forms/CancelMeetingForm";

interface CancelMeetingDialogProps {
  meetingId: number;
  userId: number;
}

const CancelMeetingDialog = ({
  meetingId,
  userId,
}: CancelMeetingDialogProps) => {
  const [isCancelDialogOpen, setCancelDialogOpen] = useState(false);

  return (
    <Dialog open={isCancelDialogOpen} onOpenChange={setCancelDialogOpen}>
      <DialogTrigger asChild>
        <Button
          className="rounded bg-destructive px-6 font-semibold text-white transition duration-300 hover:bg-destructive focus:ring-0"
          onClick={() => setCancelDialogOpen(true)}
        >
          Cancel
        </Button>
      </DialogTrigger>
      <DialogContent className="border-0 bg-primary">
        <DialogHeader>
          <DialogTitle className="text-white">
            Are you sure you want to cancel this meeting?
          </DialogTitle>
        </DialogHeader>
        <CancelMeetingForm
          userId={userId}
          meetingId={meetingId}
          onClose={() => setCancelDialogOpen(false)}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CancelMeetingDialog;
