import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useUserStore } from "@/stores";
import type { Meeting } from "@/types/meeting";
import { Label } from "@/ui";

interface NotesProfileCardProps {
  id: number;
  type: string;
  writer: string;
  description: string;
  private_meeting: boolean;
  created_at: Date | null;
  updated_at: Date | null;
  meeting: Meeting;
}

export const NotesProfileCard = ({
  id,
  type,
  writer,
  description,
  private_meeting,
  created_at,
  updated_at,
  meeting,
}: NotesProfileCardProps) => {
  const { user } = useUserStore();
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/notes/${id}`);
  };

  return (
    <Card
      onClick={handleCardClick}
      className="flex h-80 w-full cursor-pointer flex-col overflow-hidden border-none bg-gray-800 text-center text-white hover:bg-slate-700"
    >
      <CardHeader className="flex flex-col items-center bg-black/50 p-2">
        <CardTitle className="text-lg font-bold text-white">
          {meeting.target.name}
        </CardTitle>
        <p className="text-xs font-bold text-gray-400">{type}</p>
        <p className="text-xs text-gray-400">
          by {writer === user?.name ? "You" : writer}
        </p>
      </CardHeader>

      <CardContent className="flex-1 overflow-auto px-4 pb-0 pt-2 text-left">
        <strong className="text-sm">Description:</strong>
        <p className="text-sm">{description}</p>
        <Label
          label={private_meeting ? "Private note" : "Public note"}
          containerClassName="mt-2 text-center"
          className={`text-sm ${private_meeting ? "text-purple-400" : "text-green-400"}`}
        />
      </CardContent>

      <CardFooter className="flex flex-col items-center pb-2">
        <p className="text-xs text-gray-400">
          {created_at
            ? `Created at: ${format(new Date(created_at), "d MMMM, yyyy")} at ${format(new Date(created_at), "h:mm a")}`
            : "Unknown Date"}
        </p>
        <p className="text-xs text-gray-400">
          {updated_at
            ? `Updated at: ${format(new Date(updated_at), "dd/MM/yyyy")}`
            : "Not updated yet"}
        </p>
      </CardFooter>
    </Card>
  );
};
