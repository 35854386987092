import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import type { RequestSummary } from "@/types/request";
import { getInitials } from "@/utils/getInitials";

interface RequestsSummariesTableProps {
  requests: RequestSummary[];
}

export const RequestsSummariesTable = ({
  requests,
}: RequestsSummariesTableProps) => {
  const navigate = useNavigate();

  return (
    <>
      {requests.length === 0 ? (
        <div className="flex h-full items-center justify-center text-slate-400">
          <p>No feedback requests</p>
        </div>
      ) : (
        <div className="rounded-md bg-gray-800 text-white">
          <Table className="rounded-t-lg bg-gray-800">
            <TableHeader>
              <TableRow className="border-none bg-black/50 hover:bg-black/50">
                <TableHead className="w-1/3 rounded-tl-lg font-bold text-white">
                  Target
                </TableHead>
                <TableHead className="w-1/3 text-center font-bold text-white">
                  Completion
                </TableHead>
                <TableHead className="w-1/3 rounded-tr-lg text-center font-bold text-white">
                  Deadline
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="text-lg">
              {requests.map((request) => (
                <TableRow
                  data-testid={`request-row${request.id}`}
                  onClick={() => navigate(`/requests/${request.id}`)}
                  key={request.id}
                  className="cursor-pointer hover:bg-slate-700"
                >
                  <TableCell>
                    <div className="mr-8 flex items-center space-x-3">
                      <Avatar
                        className="h-8 w-8"
                        data-testid={`avatar-image-${request.target.id}`}
                      >
                        <AvatarImage src={request.target.avatarUrl} />
                        <AvatarFallback className="bg-gray-600 font-bold text-white">
                          {getInitials(request.target.name)}
                        </AvatarFallback>
                      </Avatar>
                      <small>{request.target.name}</small>
                    </div>
                  </TableCell>
                  <TableCell className="p-6 text-center">
                    <small>
                      {request.completed_feedbacks}/{request.total_feedbacks}
                    </small>
                  </TableCell>
                  <TableCell className="p-6 text-center text-sm">
                    {format(
                      new Date(request.due_date),
                      "EEEE, d MMM yyyy, h:mm a",
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="flex h-10 rounded-b-lg bg-black/50"></div>
        </div>
      )}
    </>
  );
};
