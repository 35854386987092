import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import TextAreaInput from "@/components/inputs/TextAreaInput";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { SubmitFeedbackFormData, submitFeedbackFormSchema } from "./schema";

interface SubmitFeedbackFormProps {
  defaultValues?: {
    content?: string;
  };
  writerId: number;
  feedbackId: string;
  requestId: number;
  question: string;
}

const SubmitFeedbackForm = ({
  defaultValues = { content: "" },
  requestId,
  feedbackId,
  question,
  writerId,
}: SubmitFeedbackFormProps) => {
  const navigate = useNavigate();

  const form = useForm<SubmitFeedbackFormData>({
    resolver: zodResolver(submitFeedbackFormSchema),
    defaultValues,
  });

  const { isPending, mutate: sendFeedback } = useMutation({
    mutationFn: (data: { content: string }) =>
      fetch(`/api/feedbacks/${feedbackId}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          token: writerId.toString(),
          "Content-Type": "application/json",
        },
      }),

    onSuccess: async () => {
      navigate(`/requests/${requestId}/feedbacks/${feedbackId}`);
    },
  });

  const onSubmit = (data: SubmitFeedbackFormData) => {
    sendFeedback({
      content: data.content,
    });
  };

  return (
    <Form {...form}>
      <form className="space-y-2" onSubmit={form.handleSubmit(onSubmit)}>
        <h1 className="text-xl font-bold text-white">{question}</h1>
        <TextAreaInput control={form.control} name="content" />
        <div className="flex items-center justify-end pt-1">
          <div className="flex items-center gap-x-2">
            <Button
              className="text-white"
              variant="outline"
              type="submit"
              disabled={isPending}
            >
              Save
            </Button>
            <Button
              className="text-white"
              variant="outline"
              type="button"
              onClick={() => {
                form.reset();
              }}
            >
              Discard
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default SubmitFeedbackForm;
