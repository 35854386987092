import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { LucideFileText, Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { NotesProfileCard } from "@/components/cards/NotesProfileCard";
import LoadingScreen from "@/components/LoadingScreen";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useUserStore } from "@/stores";
import { Note } from "@/types/note";
import { formatMeetingType } from "@/utils/formatMeetingType";
import { ErrorBoundaryFallback } from "../ErrorBoundaryFallback";

export const Notes = () => {
  const { user } = useUserStore();
  const userId = user!.id;
  const navigate = useNavigate();

  const [filteredNotes, setFilteredNotes] = useState<Note[]>([]);
  const [filter, setFilter] = useState("all");

  const {
    data: notes,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["userNotes", userId],
    queryFn: (): Promise<Note[]> =>
      fetch(`/api/notes`, {
        method: "GET",
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => response.data),
    enabled: !!userId,
  });

  useEffect(() => {
    if (notes) {
      handleFiltering(filter);
    }
  }, [notes, filter]);

  const handleFiltering = (selectedFilter: string) => {
    switch (selectedFilter) {
      case "public":
        setFilteredNotes(notes!.filter((note) => !note.private));
        break;
      case "private":
        setFilteredNotes(notes!.filter((note) => note.private));
        break;
      default:
        setFilteredNotes(notes!);
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error || !notes) {
    return <ErrorBoundaryFallback message="Error fetching data" />;
  }
  return (
    <div className="p-10">
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-primary-white-100">Notes</h1>
        <div className="flex items-center gap-x-4">
          <div className="flex items-center gap-x-4 text-white">
            <p>Filter by:</p>
            <Select defaultValue="all" onValueChange={setFilter}>
              <SelectTrigger className="w-[180px] bg-inherit text-white">
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="bg-slate-900 text-white">
                <SelectItem value="all">All</SelectItem>
                <SelectItem value="public">Public</SelectItem>
                <SelectItem value="private">Private</SelectItem>
              </SelectContent>
            </Select>
            <Button
              variant="outline"
              size="icon"
              className="ml-auto text-xl text-white"
              onClick={() => navigate("/notes/new")}
              aria-label="Create a new note"
            >
              <Plus />
            </Button>
          </div>
        </div>
      </div>

      {filteredNotes.length > 0 ? (
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
          {filteredNotes.map((note: Note) => (
            <NotesProfileCard
              key={note.id}
              id={note.id}
              type={formatMeetingType(note.meeting.meeting_type)}
              writer={note.writer.name}
              description={note.description}
              private_meeting={note.private}
              created_at={note.created_at}
              updated_at={note.updated_at}
              meeting={note.meeting}
            />
          ))}
        </div>
      ) : (
        <div className="mt-10 flex h-full items-center justify-center text-slate-400">
          <div className="text-center">
            <LucideFileText
              className="mx-auto mb-4 text-purple-400"
              size={48}
            />

            <p className="mb-1 text-lg font-semibold text-white">
              Looks like there are no notes yet!
            </p>
            <p>No notes available</p>
          </div>
        </div>
      )}
    </div>
  );
};
