import { useEffect, useRef, useState } from "react";
import { LucideCalendar } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { MeetingCard } from "@/components/cards/MeetingCard";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import type { Meeting } from "@/types/meeting";

interface MeetingsCarouselProps {
  meetings: Meeting[];
  ownProfile: boolean;
}

export const MeetingsCarousel = ({
  meetings,
  ownProfile,
}: MeetingsCarouselProps) => {
  const navigate = useNavigate();

  const panelRef = useRef(null);
  const [panelWidth, setPanelWidth] = useState(0);

  // Use ResizeObserver to monitor panel width
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        setPanelWidth(entries[0].contentRect.width);
      }
    });

    if (panelRef.current) {
      observer.observe(panelRef.current);
    }

    return () => {
      if (panelRef.current) {
        observer.unobserve(panelRef.current);
      }
    };
  }, []);

  // Decide how many cards to show based on panel width
  const getCardClass = () => {
    if (panelWidth < 600) {
      return "basis-full";
    } else if (panelWidth < 900) {
      return "basis-1/2";
    } else {
      return "basis-1/3";
    }
  };

  const message = ownProfile
    ? "No meetings where you're the target"
    : "No meetings where they're the target";

  return (
    <div ref={panelRef} className="px-12">
      {meetings.length === 0 ? (
        <div className="mt-10 flex h-full items-center justify-center text-slate-400">
          <div className="text-center">
            <LucideCalendar className="mx-auto mb-4 text-gray-500" size={48} />

            <p className="mb-1 text-lg font-semibold text-white">
              Looks like you're free for now!
            </p>
            <p>{message}</p>
          </div>
        </div>
      ) : (
        <Carousel
          className="relative mx-auto w-full px-0"
          opts={{ loop: false }}
        >
          <CarouselPrevious />
          <CarouselContent className="flex">
            {meetings.map((meeting) => (
              <CarouselItem
                onClick={() => navigate(`/meetings/${meeting.id}`)}
                key={meeting.id}
                className={getCardClass()}
              >
                <MeetingCard meeting={meeting} />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselNext />
        </Carousel>
      )}
    </div>
  );
};
