import { z } from "zod";

export const newRoundFormSchema = z
  .object({
    name: z.string(),
    starting_date: z.date().refine((date) => date >= new Date(), {
      message: "The starting date cannot be in the past.",
    }),
    finishing_date: z.date().refine((date) => date >= new Date(), {
      message: "The finishing date cannot be in the past.",
    }),
  })
  .superRefine((data, ctx) => {
    if (data.finishing_date <= data.starting_date) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["finishing_date"],
        message: "Must be after the starting date.",
      });
    }
  });

export type NewRoundFormData = z.infer<typeof newRoundFormSchema>;
