import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useParams } from "react-router-dom";

import SmallUserCard from "@/components/cards/SmallUserCard";
import TargetUserCard from "@/components/cards/TargetUserCard";
import NoteCarousel from "@/components/carousels/MeetingNoteCarousel";
import TopicCarousel from "@/components/carousels/TopicCarousel";
import ActionItemCollapsible from "@/components/collapsibles/ActionItemCollapsible";
import CancelMeetingDialog from "@/components/dialogs/CancelMeetingDialog";
import FinishMeetingDialog from "@/components/dialogs/FinishMeetingDialog";
import TriggerQuestionsDialog from "@/components/dialogs/TriggerQuestionsDialog";
import UpdateMeetingDialog from "@/components/dialogs/UpdateMeetingDialog";
import SetFeelingForm from "@/components/forms/SetFeelingForm";
import LoadingScreen from "@/components/LoadingScreen";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { useUserStore } from "@/stores";
import { MeetingState } from "@/types/meeting";
import { MeetingData } from "@/types/meeting_data";
import { User } from "@/types/user";
import { Button } from "@/ui";
import { formatMeetingType } from "@/utils/formatMeetingType";
import { ErrorBoundaryFallback } from "../ErrorBoundaryFallback";

export const MeetingDetails = () => {
  const { id } = useParams();
  const { user } = useUserStore();

  const {
    data: response,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["meetings", id],
    queryFn: (): Promise<{ data: MeetingData }> =>
      fetch(`/api/meetings/${id}`, {
        method: "GET",
        headers: {
          token: user!.id.toString(),
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
    enabled: !!id,
  });

  const meeting = response?.data;

  const {
    data: questions,
    isLoading: isLoadingQuestions,
    error: isErrorQuestions,
  } = useQuery({
    queryFn: (): Promise<{ data: { question: string[] } }> =>
      fetch(`/api/meetings/${id}/static_questions`, {
        method: "GET",
        headers: {
          token: user!.id.toString(),
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
    enabled: !!id,
    queryKey: ["questions", id],
  });

  const question = questions ? Object.values(questions.data.question) : [];

  const [previousMeeting, setPreviousMeeting] = useState<MeetingData | null>(
    null,
  );

  const { mutate: changeMeeting } = useMutation({
    mutationFn: (data: { meeting_id: number }) =>
      fetch(`/api/meetings/${data.meeting_id}`, {
        method: "GET",
        headers: {
          token: user!.id.toString(),
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
    onSuccess: (response) => {
      setPreviousMeeting(response.data);
    },
    onError: () => {
      setPreviousMeeting(null);
    },
  });

  const handleChangeMeeting = (meetingId: number) => {
    changeMeeting({
      meeting_id: meetingId,
    });
  };

  useEffect(() => {
    if (meeting && meeting.previous_meeting_id) {
      handleChangeMeeting(meeting.previous_meeting_id!);
    } else {
      setPreviousMeeting(null);
    }
  }, [meeting]);

  const {
    data: users,
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
  } = useQuery({
    queryFn: (): Promise<User[]> =>
      fetch("/api/users", {
        method: "GET",
      })
        .then((res) => res.json())
        .then((res) => res.data),
    queryKey: ["users"],
  });

  const {
    data: targets,
    isLoading: isLoadingTargets,
    isError: isErrorTargets,
  } = useQuery({
    queryFn: (): Promise<User[]> =>
      fetch(`/api/users/${user!.email}/employees`)
        .then((res) => res.json())
        .then((res) => res.data),
    queryKey: ["employees"],
  });

  if (isLoading || isLoadingTargets || isLoadingUsers || isLoadingQuestions) {
    return <LoadingScreen />;
  }

  if (
    isErrorTargets ||
    isErrorUsers ||
    !users ||
    !targets ||
    isErrorQuestions
  ) {
    return <ErrorBoundaryFallback message="Error fetching user data" />;
  }

  if (error || !meeting) {
    return <ErrorBoundaryFallback message="Error fetching meeting data" />;
  }

  /* Current meeting data */
  const title = `${meeting.meeting_type ? `${formatMeetingType(meeting.meeting_type)} Meeting` : "Type"} - ${meeting.meeting_date ? format(new Date(meeting.meeting_date), "EEEE, d MMMM, yyyy") : "Date"}`;
  const meetingState = meeting.state;
  const isEditable =
    meetingState != MeetingState.cancelled && meetingState != MeetingState.done;
  const publicNotes = meeting.public_notes;
  const privateNotes = meeting.private_notes;
  const actionItems = meeting.action_items;
  const topics = meeting.topics;
  const howIFeel = meeting.participants.find(
    (participant) => participant.user_id === user!.id,
  )?.feeling;

  /* Previous meeting data */
  const previousPublicNotes = previousMeeting?.public_notes;
  const previousPrivateNotes = previousMeeting?.private_notes;
  const previousActionItems = previousMeeting?.action_items;
  const previousTopics = previousMeeting?.topics;
  const previousHowIFeel = previousMeeting?.participants.find(
    (participant) => participant.user_id === user!.id,
  )?.feeling;

  return (
    <div className="mb-2 flex min-h-screen w-full flex-col text-white">
      {/* Header */}
      <div className="relative m-4 flex flex-row items-center justify-between rounded-lg p-2 outline outline-1 outline-white">
        {/* Meeting target, participants and state*/}
        <div className="m-4 flex w-full flex-col">
          <h1 className="m-2 flex justify-center text-center text-4xl font-bold">
            {title}
          </h1>
          <div>
            {/* Cancelled information */}
            {meetingState == MeetingState.cancelled && (
              <div className="py-2">
                <h1 className="text-left text-xl font-bold">
                  This meeting has been cancelled
                </h1>
                <div className="text-justify">
                  <strong>Motive: </strong>
                  <span className="mx-1"></span>
                  {meeting.meeting_cancelation &&
                  meeting.meeting_cancelation.motive ? (
                    <>{meeting.meeting_cancelation.motive}</>
                  ) : (
                    <>No cancellation motive recorded.</>
                  )}
                </div>
              </div>
            )}
            <h1 className="text-left text-xl font-bold">Target</h1>
            <div className="flex py-2">
              <TargetUserCard
                id={meeting.target.id}
                name={meeting.target.name}
                email={meeting.target.email}
                avatarUrl={meeting.target.avatarUrl}
                hours={meeting.target.hours}
                jobTitle={meeting.target.jobTitle}
                lastPosition={meeting.target.lastPosition}
              />
            </div>
            <h1 className="mt-1 text-left text-xl font-bold">Participants</h1>
            <div className="flex flex-row flex-wrap">
              {meeting.participants.map((participant) => (
                <div key={participant.user_id} className="py-2 pl-0 pr-4">
                  <SmallUserCard
                    id={participant.user_id}
                    name={participant.name}
                    email={participant.email}
                    avatarUrl={participant.avatarUrl}
                  />
                </div>
              ))}
            </div>
          </div>
          {/* Meeting summary */}
          {meetingState == MeetingState.done && (
            <div className="pt-2">
              <h1 className="text-left text-xl font-bold">
                This meeting has already finished
              </h1>
              <div className="text-justify">
                <strong className="mr-1">Meeting summary:</strong>
                {meeting.summary ? (
                  <>{meeting.summary}</>
                ) : (
                  <>No summary available.</>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Meeting Panels */}
      <div className="relative m-4 flex flex-row rounded-lg outline outline-1 outline-white">
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel defaultSize={meeting.previous_meeting_id ? 50 : 0}>
            {/* Previous Meeting Information */}
            <div className="relative m-4 flex h-full max-w-full flex-1 flex-col overflow-x-auto rounded-lg bg-gray-900 p-2 text-white shadow-lg">
              {previousMeeting ? (
                <div className="flex flex-col gap-y-4">
                  {/* Header */}
                  <div className="mb-4 flex w-full items-center justify-between">
                    <div>
                      {previousMeeting.previous_meeting_id && (
                        <Button
                          disabled={!previousMeeting.previous_meeting_id}
                          className="group items-center bg-transparent p-0 font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white focus:ring-0"
                          onClick={() =>
                            handleChangeMeeting(
                              previousMeeting.previous_meeting_id!,
                            )
                          }
                        >
                          <ArrowLeft className="h-8 w-8" />
                        </Button>
                      )}
                    </div>
                    <h1 className="text-center text-3xl font-bold">
                      {previousMeeting.meeting_date
                        ? format(
                            new Date(previousMeeting.meeting_date),
                            "EEEE, d MMMM, yyyy",
                          )
                        : "Date"}
                    </h1>
                    <div>
                      {previousMeeting.previous_meeting_id && (
                        <Button
                          disabled={
                            !previousMeeting.next_meeting_id ||
                            previousMeeting.next_meeting_id == meeting.id
                          }
                          className="group items-center bg-transparent p-0 font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white focus:ring-0"
                          onClick={() =>
                            handleChangeMeeting(
                              previousMeeting.next_meeting_id!,
                            )
                          }
                        >
                          <ArrowRight className="h-8 w-8" />
                          <span className="sr-only">Next slide</span>
                        </Button>
                      )}
                    </div>
                  </div>

                  {/* How I Feel */}
                  <div>
                    <h1 className="mb-1 text-xl font-bold">How I Feel</h1>
                    <SetFeelingForm
                      userId={user!.id}
                      meetingId={meeting.id}
                      defaultValues={
                        previousHowIFeel ? { how_i_feel: previousHowIFeel } : {}
                      }
                      disabled={true}
                    />
                  </div>

                  {/* Topics */}
                  <TopicCarousel
                    topics={previousTopics!}
                    isEditable={false}
                    userId={user!.id}
                    meetingId={meeting.id}
                  />

                  {/* Action Items */}
                  <ActionItemCollapsible
                    actionItems={previousActionItems!}
                    isEditable={false}
                    userId={user!.id}
                    meetingId={meeting.id}
                    targets={meeting.participants}
                  />

                  {/* Public Notes */}
                  <NoteCarousel
                    notes={previousPublicNotes!}
                    isPrivate={false}
                    isEditable={false}
                    userId={user!.id.toString()}
                    meetingId={parseInt(id!, 10)}
                  />
                  {/* Private Notes */}
                  <NoteCarousel
                    notes={previousPrivateNotes!}
                    isPrivate={true}
                    isEditable={false}
                    userId={user!.id.toString()}
                    meetingId={parseInt(id!, 10)}
                  />
                </div>
              ) : (
                <div className="mb-4 flex w-full justify-center">
                  <h1 className="text-center text-3xl font-bold">
                    No previous meeting
                  </h1>
                </div>
              )}
            </div>
          </ResizablePanel>
          <ResizableHandle />
          <ResizablePanel>
            {/* Current Meeting Information */}
            <div className="relative m-4 flex h-full max-w-full flex-1 flex-col gap-y-4 overflow-x-auto rounded-lg bg-gray-900 p-2 text-white shadow-lg">
              {/* Header */}
              <div className="relative mb-4 flex w-full items-center">
                <h1 className="flex-grow text-center text-3xl font-bold">
                  Current Meeting
                </h1>
                <div className="absolute right-0">
                  {/* Trigger Questions */}
                  <TriggerQuestionsDialog
                    question={question}
                    userId={user!.id}
                    meetingId={meeting?.id}
                  />
                </div>
              </div>

              {/* How I Feel */}
              <div>
                <h1 className="mb-1 text-xl font-bold">How I Feel</h1>
                <SetFeelingForm
                  userId={user!.id}
                  meetingId={meeting.id}
                  defaultValues={howIFeel ? { how_i_feel: howIFeel } : {}}
                  disabled={
                    howIFeel !== null ||
                    !isEditable ||
                    new Date() < new Date(meeting.meeting_date)
                  }
                />
              </div>

              {/* Topics */}
              <TopicCarousel
                topics={topics}
                isEditable={isEditable}
                userId={user!.id}
                meetingId={meeting.id}
              />

              {/* Action Items */}
              <ActionItemCollapsible
                actionItems={actionItems}
                isEditable={isEditable}
                userId={user!.id}
                meetingId={meeting.id}
                targets={meeting.participants}
              />

              {/* Public Notes */}
              <NoteCarousel
                notes={publicNotes}
                isPrivate={false}
                isEditable={isEditable}
                userId={user!.id.toString()}
                meetingId={parseInt(id!, 10)}
              />

              {/* Private Notes */}
              <NoteCarousel
                notes={privateNotes}
                isPrivate={true}
                isEditable={isEditable}
                userId={user!.id.toString()}
                meetingId={parseInt(id!, 10)}
              />
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>

      {/* Footer */}
      {isEditable && meeting.created_by.id == user!.id && (
        <div className="flex flex-row justify-center gap-2">
          {/* Update a Meeting */}
          <UpdateMeetingDialog
            meeting={meeting}
            userId={user!.id}
            userEmail={user!.email}
          />

          {/* Set Meeting as Done */}
          {new Date() > new Date(meeting.meeting_date) && (
            <FinishMeetingDialog userId={user!.id.toString()} meetingId={id!} />
          )}

          {/* Cancel a Meeting */}
          <CancelMeetingDialog userId={user!.id} meetingId={meeting.id} />
        </div>
      )}
    </div>
  );
};
