import { format } from "date-fns";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface NoteMeetingCardProps {
  id: number;
  writer: string;
  description: string;
  private_note: boolean;
  created_at: Date | null;
  updated_at: Date | null;
}

export const NoteMeetingCard = ({
  writer,
  description,
  private_note,
  created_at,
  updated_at,
}: NoteMeetingCardProps) => {
  return (
    <Card className="flex h-56 w-full flex-col overflow-hidden border-none bg-gray-800 text-center text-white">
      {/* Header section */}
      <CardHeader className="flex flex-col items-center bg-black/50 p-2">
        {/* Fixed height for the header */}
        <CardTitle className="text-lg text-white">
          {private_note ? "Private Note" : "Public Note"}
        </CardTitle>
        <h1 className="text-xs text-gray-400">by {writer}</h1>
      </CardHeader>

      {/* Content section */}
      <CardContent className="flex-1 overflow-auto px-4 pb-0 pt-2 text-left">
        {/* Allow overflow */}

        <strong className="text-sm">Description: </strong>
        <p className="text-sm">{description}</p>
      </CardContent>

      {/* Footer section */}
      <CardFooter className="flex flex-col items-center pb-2">
        <p className="text-xs text-gray-400">
          {updated_at
            ? `Updated at: ${format(new Date(updated_at), "d/MM/yyyy")}`
            : `Created at: ${format(new Date(created_at!), "d/MM/yyyy")}`}
        </p>
      </CardFooter>
    </Card>
  );
};

export default NoteMeetingCard;
