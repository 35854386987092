import { useNavigate } from "react-router-dom";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { getInitials } from "@/utils/getInitials";

interface SmallUserCardProps {
  id: number;
  name: string;
  email: string;
  avatarUrl: string;
  icon?: React.ReactNode;
}
const SmallUserCard = ({
  id,
  name,
  email,
  avatarUrl,
  icon,
}: SmallUserCardProps) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex cursor-pointer flex-row overflow-auto rounded-md bg-gray-800"
      onClick={() => navigate(`/employees/${id}`)}
    >
      <Avatar className="h-12 w-12 rounded-none">
        <AvatarImage src={avatarUrl} />
        <AvatarFallback className="rounded-none text-xl text-black">
          {getInitials(name)}
        </AvatarFallback>
      </Avatar>
      <div className="flex flex-col justify-center px-2 text-left">
        <div className="flex items-center space-x-2">
          <strong>{name}</strong>
          {icon && <span className="px-1">{icon}</span>}
        </div>
        <div className="text-xs text-gray-500">{email}</div>
      </div>
    </div>
  );
};

export default SmallUserCard;
