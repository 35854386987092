import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import ToDoActionItemCollapsible from "@/components/collapsibles/ToDoActionItemsCollapsible";
import LoadingScreen from "@/components/LoadingScreen";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useUserStore } from "@/stores";
import type { ActionItem } from "@/types/action_item";
import { ErrorBoundaryFallback } from "../ErrorBoundaryFallback";

export const ActionItems = () => {
  const { user } = useUserStore();
  const userId = user!.id;

  const [showActionItems, setShowActionItems] = useState<ActionItem[]>([]);
  const [filter, setFilter] = useState<string>("all");

  const {
    data: actionItems,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["actionItems"],
    queryFn: (): Promise<ActionItem[]> =>
      fetch("api/action-items/", {
        method: "GET",
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => response.data),
  });

  useEffect(() => {
    if (actionItems) {
      setShowActionItems(actionItems);
      handleFiltering(filter);
    }
  }, [actionItems]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error || !actionItems) {
    return <ErrorBoundaryFallback message="Error fetching employee data" />;
  }

  const handleFiltering = (e: string) => {
    setFilter(e);
    switch (e) {
      case "completed":
        setShowActionItems(actionItems.filter((item) => item.completed));
        break;
      case "todo":
        setShowActionItems(actionItems.filter((item) => !item.completed));
        break;
      default:
        setShowActionItems(actionItems);
    }
  };

  const func = () => {
    refetch();
    handleFiltering(filter);
  };

  return (
    <section className="p-10 text-white">
      <div className="mb-8 flex items-center justify-between border-b border-white pb-2">
        <h1 className="text-whitem relative mb-2 text-2xl font-bold">
          Action Items
        </h1>
        <div className="flex items-center gap-x-4">
          <p>Filter by:</p>
          <Select defaultValue="all" onValueChange={handleFiltering}>
            <SelectTrigger className="w-[180px] bg-inherit text-white">
              <SelectValue className="bg-green-500 text-white" />
            </SelectTrigger>
            <SelectContent className="bg-slate-900 text-white">
              <SelectItem value="all">All</SelectItem>
              <SelectItem value="completed">Completed</SelectItem>
              <SelectItem value="todo">To do</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      {!showActionItems.length ? (
        <p>No action items available</p>
      ) : (
        <ToDoActionItemCollapsible
          actionItems={showActionItems}
          userId={user!.id}
          ownProfile={true}
          refetch={func}
        />
      )}
    </section>
  );
};
