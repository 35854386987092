import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";

import { NewRequestForm } from "@/components/forms/NewRequestForm";
import LoadingScreen from "@/components/LoadingScreen";
import { ErrorBoundaryFallback } from "@/screens/ErrorBoundaryFallback";
import { User } from "@/types/user";

export const CreateRequestScreen = () => {
  const { id: roundId } = useParams();
  const location = useLocation();
  const { roundStartDate, roundEndDate } = location.state || {};

  const {
    data: users,
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
    error: usersError,
  } = useQuery({
    queryFn: (): Promise<User[]> =>
      fetch(`/api/users`)
        .then((res) => res.json())
        .then((res) => res.data),
    queryKey: ["users"],
  });

  if (isLoadingUsers || isFetchingUsers) return <LoadingScreen />;

  const error = usersError;

  if (error) return <ErrorBoundaryFallback message={error.message} />;

  if (!users) return <ErrorBoundaryFallback message="Error fetching users." />;

  return (
    <div className="space-y-4 p-10">
      <h1 className="text-2xl font-bold text-primary-white-100">
        Create Feedback Request
      </h1>
      <NewRequestForm
        reviewers={users}
        targets={users}
        roundId={roundId!}
        roundStartDate={new Date(roundStartDate)}
        roundEndDate={new Date(roundEndDate)}
      />
    </div>
  );
};
