import { format } from "date-fns";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { User } from "@/types/user";

interface FeedbackCard {
  reviewer: User;
  content: string;
  feeling: string;
  submitted_at: string;
}

const FeedbackCard = ({
  reviewer,
  content,
  feeling,
  submitted_at,
}: FeedbackCard) => {
  return (
    <Card className="flex h-80 w-full cursor-pointer flex-col overflow-hidden border-none bg-gray-800 text-center text-white">
      {/* Header section */}
      <CardHeader className="flex flex-col items-center bg-black/50 py-4">
        <CardTitle className="text-lg font-bold text-white">
          Feedback from: <span className="text-gray-300">{reviewer.name}</span>
        </CardTitle>
      </CardHeader>
      {/* Content section */}
      <CardContent className="mt-2 flex-1 pb-0 text-left">
        {/* Allow overflow and limit height */}
        <div className="mb-2 flex max-h-[10.5rem] flex-col overflow-y-auto">
          <strong>Feedback:</strong>
          <h1 className="text-left text-gray-300">{content}</h1>
        </div>
        {feeling && (
          <div className="mb-2 flex">
            <strong>Feeling:</strong>
            <h1 className="ml-1 text-left text-gray-300">
              {feeling.charAt(0).toUpperCase() + feeling.slice(1)}
            </h1>
          </div>
        )}
      </CardContent>
      {/* Footer section */}
      <CardFooter className="flex flex-col items-center pb-2">
        <p className="text-sm text-gray-400">
          Submitted on:{" "}
          {submitted_at
            ? `${format(new Date(submitted_at), "d/MM/yyyy")}`
            : `N/A`}
        </p>
      </CardFooter>
    </Card>
  );
};

export default FeedbackCard;
