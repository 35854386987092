import { useNavigate } from "react-router-dom";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Feedback } from "@/types/feedback";
import FeedbackCard from "../cards/FeedbackCard";

interface FeedbackCarouselProps {
  feedbacks: Feedback[];
}

export const FeedbackCarousel = ({ feedbacks }: FeedbackCarouselProps) => {
  const navigate = useNavigate();
  return (
    <div className="px-5">
      {feedbacks.length === 0 ? (
        <div className="flex h-full items-center justify-center text-slate-400">
          <p>No feedbacks available</p>
        </div>
      ) : (
        <Carousel
          className="relative mx-auto w-full px-0 lg:px-5"
          opts={{ loop: false }}
        >
          <CarouselPrevious />
          <CarouselContent className="flex">
            {feedbacks.map((feedback) => (
              <CarouselItem
                onClick={() =>
                  navigate(
                    `/requests/${feedback.request_id}/feedbacks/${feedback.id}`,
                  )
                }
                key={feedback.id}
                className="flex-shrink-0 flex-grow-0 lg:basis-1/2 xl:basis-1/3"
              >
                <FeedbackCard
                  reviewer={feedback.reviewer}
                  content={feedback.content!}
                  feeling={feedback.feeling!}
                  submitted_at={feedback.submitted_at!}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselNext />
        </Carousel>
      )}
    </div>
  );
};
