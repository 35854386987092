import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { MessageCircleQuestion } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import LoadingScreen from "../LoadingScreen";

interface TriggerQuestionsDialogProps {
  question: String[];
  userId: number;
  // Id for the meeting to get the AI generated questions.
  meetingId: number;
}

const TriggerQuestionsDialog = ({
  question,
  userId,
  meetingId,
}: TriggerQuestionsDialogProps) => {
  const [isTriggerQuestionsDialogOpen, setTriggerQuestionsDialogOpen] =
    useState(false);
  const [isAddQuestionsDialogOpen, setAddQuestionsDialogOpen] = useState(false);
  const [aiQuestions, setAiQuestions] = useState<string[]>([]); // Store the questions
  const [hasFetchedQuestions, setHasFetchedQuestions] = useState(false); // Prevent multiple queries

  const {
    isLoading: isLoadingObtainedQuestions,
    error: isErrorObtainedQuestions,
    refetch: fetchQuestions,
  } = useQuery({
    queryFn: (): Promise<{ data: { question: string[] } }> =>
      fetch(`/api/meetings/${meetingId}/trigger_questions`, {
        method: "GET",
        headers: {
          token: userId.toString(),
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
    enabled: false,
    queryKey: ["trigger_questions", meetingId],
  });

  useEffect(() => {
    if (isTriggerQuestionsDialogOpen && !hasFetchedQuestions) {
      fetchQuestions().then((obtainedQuestions) => {
        if (obtainedQuestions.data) {
          setAiQuestions(Object.values(obtainedQuestions.data.data.question));
          setHasFetchedQuestions(true);
        }
      });
    }
  }, [isTriggerQuestionsDialogOpen, fetchQuestions, hasFetchedQuestions]);

  return (
    <Dialog
      open={isTriggerQuestionsDialogOpen}
      onOpenChange={setTriggerQuestionsDialogOpen}
      modal={false}
    >
      <DialogTrigger asChild>
        <button className="flex flex-col items-center rounded-full bg-transparent text-gray-400 transition-transform duration-200 hover:scale-110 hover:text-white focus:outline-none">
          <MessageCircleQuestion />
          <span className="text-sm font-semibold">Questions</span>
        </button>
      </DialogTrigger>

      <DialogContent className="border-0 bg-primary">
        <DialogHeader>
          <DialogTitle className="text-white">Trigger Questions</DialogTitle>
        </DialogHeader>
        <DialogDescription className="text-gray-200">
          {question.length > 0 ? (
            question.map((q, index) => (
              <li className="mb-2 ml-2 list-decimal" key={index}>
                {q}
              </li>
            ))
          ) : (
            <li>No questions available.</li>
          )}
        </DialogDescription>
        <DialogFooter className="flex justify-between">
          <Dialog
            open={isAddQuestionsDialogOpen}
            onOpenChange={setAddQuestionsDialogOpen}
          >
            <DialogTrigger asChild>
              <Button
                className="flex items-center space-x-2 rounded bg-green-600 px-4 py-2 text-white hover:bg-green-700 focus:outline-none"
                onClick={() => {
                  setAddQuestionsDialogOpen(true);
                }}
              >
                <span>Suggested Questions</span>
              </Button>
            </DialogTrigger>
            <DialogContent className="border-0 bg-primary">
              <DialogHeader>
                <DialogTitle className="text-white">
                  Suggested Questions
                </DialogTitle>
              </DialogHeader>
              <DialogDescription className="text-gray-200">
                {isLoadingObtainedQuestions ? (
                  <div className="flex h-20 w-full items-center justify-center">
                    <LoadingScreen />
                  </div>
                ) : isErrorObtainedQuestions ? (
                  <div>Error: error fetching data</div>
                ) : (
                  <>
                    <p className="mb-2 text-gray-400">
                      These questions are generated by AI to help guide the
                      conversation:
                    </p>
                    <ul>
                      {aiQuestions.length > 0 ? (
                        aiQuestions.map((q, index) => (
                          <li className="mb-2 ml-4 list-disc" key={index}>
                            {q}
                          </li>
                        ))
                      ) : (
                        <li>No questions available.</li>
                      )}
                    </ul>
                  </>
                )}
              </DialogDescription>
              <DialogFooter></DialogFooter>
            </DialogContent>
          </Dialog>
          <Button
            className="rounded bg-destructive px-6 py-2 font-semibold text-white transition duration-300 hover:bg-red-700 focus:ring-0"
            onClick={() => setTriggerQuestionsDialogOpen(false)}
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TriggerQuestionsDialog;
