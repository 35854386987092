import { useQuery } from "@tanstack/react-query";
import { format, formatDistanceToNow } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";

import TargetUserCard from "@/components/cards/TargetUserCard";
import SubmitFeedbackForm from "@/components/forms/SubmitFeedbackForm";
import LoadingScreen from "@/components/LoadingScreen";
import { ErrorBoundaryFallback } from "@/screens/ErrorBoundaryFallback";
import { useUserStore } from "@/stores";
import { FeedbackWithRequest } from "@/types/feedback";

export const SendFeedback = () => {
  const { user } = useUserStore();

  const { id } = useParams();

  const navigate = useNavigate();

  const {
    data: response,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["feedbacks", id],
    queryFn: (): Promise<{ data: FeedbackWithRequest }> =>
      fetch(`/api/feedbacks/${id}`, {
        method: "GET",
        headers: {
          token: user!.id.toString(),
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
    enabled: !!id,
  });

  const feedbackRequest = response?.data;

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) return <ErrorBoundaryFallback message={error.message} />;

  if (!feedbackRequest)
    return <ErrorBoundaryFallback message="Error fetching meeting data" />;

  if (feedbackRequest.content != null) {
    navigate(
      `/requests/${feedbackRequest.request_id}/feedbacks/${feedbackRequest.id}`,
    );
  }

  const due_date = new Date(feedbackRequest.request.due_date);

  if (new Date() > due_date) {
    navigate(`/rounds`);
  }

  const target = feedbackRequest.request.target;

  return (
    <div className="p-10">
      <h1 className="pb-5 text-5xl font-bold text-white">Send feedback</h1>
      <div className="space-y-8">
        <div className="flex flex-col gap-x-5 text-white xl:flex-row">
          <div className="flex-1">
            <h1 className="py-2 text-2xl font-bold">Target</h1>
            <TargetUserCard
              id={target.id}
              name={target.name}
              email={target.email}
              avatarUrl={target.avatarUrl}
              hours={target.hours}
              jobTitle={target.jobTitle}
              lastPosition={target.lastPosition}
            />
          </div>
          <div className="flex-1">
            <h1 className="py-2 text-2xl font-bold">Deadline</h1>
            <div className="flex flex-wrap gap-x-2">
              <h1 className="text-xl">
                {format(due_date, "EEEE, d MMMM, yyyy")}
              </h1>
              <h1 className="text-xl">
                (
                {formatDistanceToNow(due_date, {
                  addSuffix: true,
                })}
                )
              </h1>
            </div>
          </div>
        </div>
        <SubmitFeedbackForm
          question={feedbackRequest.request.question}
          requestId={feedbackRequest.request.id}
          feedbackId={id!}
          writerId={user!.id}
        />
      </div>
    </div>
  );
};
