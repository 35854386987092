import { format } from "date-fns";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useUserStore } from "@/stores";
import type { Meeting } from "@/types/meeting";
import { formatMeetingType } from "@/utils/formatMeetingType";
import { formatParticipantList } from "@/utils/formatParticipantList";

interface MeetingCardProps {
  meeting: Meeting;
}

export const MeetingCard = ({ meeting }: MeetingCardProps) => {
  const { user } = useUserStore();

  return (
    <Card className="flex h-full w-full cursor-pointer flex-col overflow-hidden border-none bg-gray-800 text-center text-white hover:bg-slate-700">
      <CardHeader className="flex flex-col items-center bg-black/50 p-2">
        <CardTitle className="text-xl font-bold text-white">
          {formatMeetingType(meeting.meeting_type)} {meeting.relative_time}
        </CardTitle>
        <h1 className="mt-1 text-sm text-gray-400">
          Target: {meeting.target.name}
        </h1>
        <h1 className="text-sm text-gray-400">Location: {meeting.location}</h1>
      </CardHeader>
      <CardContent className="flex-1 overflow-auto px-4 pb-0 pt-2 text-left">
        <CardDescription className="text-md mb-4 text-wrap">
          <>
            <p className="text-white">Participants:</p>
            <div className="text-primary-white-700">
              {!meeting.participants ? (
                <p> No participants </p>
              ) : (
                formatParticipantList(
                  meeting.participants.map((p) => p.name),
                  user!.name,
                )
              )}
            </div>
          </>
        </CardDescription>
      </CardContent>

      <CardFooter className="flex flex-col items-center pb-2">
        <p className="text-xs text-gray-400">
          {format(new Date(meeting.meeting_date), "EEEE, d MMMM, yyyy")}
        </p>
        <p className="text-xs text-gray-400">
          {format(new Date(meeting.meeting_date), "h:mm a")}
        </p>
      </CardFooter>
    </Card>
  );
};
